import { useEffect, useState } from "react"
import { Icon } from "semantic-ui-react"
import { Modal } from 'react-bootstrap'
import { MEDIA_URL } from "../../../api/axios"
import useAuth from "../../../hooks/useAuth"
import useCompanyServices from "../../../services/companyServices"
import ToggleButton from "../../agents/components/Toggel_button"
import profileImg from "../../../assets/images/imgs/user.png"

const StaffCard = ({ img, user, role, data, open, edit, refresh }) => {
    const { auth } = useAuth()
    const [showImageHover, setShowImageHover] = useState(false)

    const { deleteCompanyStaffDetails } = useCompanyServices()

    const [showModal, setShowModal] = useState(false)
    const [staffData, setStaffData] = useState("")
    useEffect(() => {
        if (auth.role === "company") {
            setStaffData(data.report)
        } else if (auth.role === "agent") {
            setStaffData(data?.report)
        }
    }, [data])

    // useEffect(()=>{
    //     if(img === ""){
    //         img = profileImg
    //     }else{
    //         img = MEDIA_URL+img
    //     }
    // },[img])

    const handleClose = () => setShowModal(false)

    const handleSubmit = async (e) => {
        try {
            const response = await deleteCompanyStaffDetails(auth.role === "company" ? data.id : "")
            if (response?.success) {
                refresh()
                handleClose()
            }
        } catch (err) {

        }
    }

    const PopoverHoverFocusImage =()=>{
        return(
        <div
            className={`agent-hover-card py-1 ps-0 col-12 row ${showImageHover?'show':'hide'}`}
            // style={{marginLeft:'-1rem'}}
        >
            {/* <div className="row py-2 pe-0"> */}
                <div 
                    className="col-md-5 staff-card-img pe-0 py-2 text-center"
                    style={{paddingLeft:'.75rem'}}
                >
                    <img 
                        src={img ? MEDIA_URL+img: profileImg}
                        className=""
                        alt=""
                        onMouseEnter={()=>setShowImageHover(true)}
                        onMouseLeave={()=>setShowImageHover(false)}
                    />
                    <div className="col-12 ms-1 me-0 text-center">
                        <div className="agent-hover-name">{user}</div>
                        <div className="agent-hover-role">{role}</div>
                    </div>
                </div>
                <div className="col-md-7 ps-4 py-0 pe-2">
                    <table className="table table-borderless align-items-center mb-1 ps-5 pe-0">
                        <tbody>
                            <tr>
                                <th className="agent-hover-label">Today's Lead</th>
                                <td className="agent-hover-data">{staffData.total_leads || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Leads Opened</th>
                                <td className="agent-hover-data">{staffData.leads_completed || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Leads Closed</th>
                                <td className="agent-hover-data">{staffData.leads_closed || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Leads Rejected</th>
                                <td className="agent-hover-data">{staffData.leads_rejected || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Total Followups</th>
                                <td className="agent-hover-data">{staffData.total_followUps || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Followups Completed</th>
                                <td className="agent-hover-data">{staffData.follow_ups_completed || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Tasks Pending</th>
                                <td className="agent-hover-data">{staffData.total_not_completed_task || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Tasks Completed</th>
                                <td className="agent-hover-data">{staffData.total_completed_task || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Incoming Calls</th>
                                <td className="agent-hover-data">{staffData.incoming_calls || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Outgoing Calls</th>
                                <td className="agent-hover-data">{staffData.outgoing_calls || 0}</td>
                            </tr>
                        </tbody>
                    </table>
                {/* </div> */}
            </div>
        </div>
    )
    } 

    return (
        <div className="col-lg-4 col-md-6 my-3">
            <PopoverHoverFocusImage />
            {window.innerWidth > 768 || auth.role === "company" ? 
            <div className={`staff-card col-12 row py-1 ${!data.is_active&&"not_active"}`}>
                <div className="col-5 staff-card-img my-2">
                    <img
                        src={img ? MEDIA_URL + img:profileImg}
                        className=""
                        alt=""
                        onMouseEnter={()=>setShowImageHover(true)}
                        onMouseLeave={()=>setShowImageHover(false)}
                    />
                </div>
                <div className="col-7 my-2 position-relative px-4">
                    <div className="row text-center mb-1">
                        <div className="col-6 ">
                            <div className="staff-card-data">{(staffData && staffData.total_leads) || 0}</div>
                            <div className="staff-card-label">Lead</div>
                        </div>
                        <div className="col-6">
                            <div className="staff-card-data">{(staffData && staffData.total_followUps) || 0}</div>
                            <div className="staff-card-label">Followups</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="staff-card-name col-9">{user}</div>
                        {auth.role==='company'&&<ToggleButton data={data} refresh={refresh}/>}
                        <div className="staff-card-role">{role}</div>
                    </div>
                    {auth.role === "company" && <div className="row staff-card-btnholder me-1">
                        <div className="">
                            <button disabled={!data?.is_active} onClick={() => edit(data)} className="btn staff-card-btn px-2 mx-1 btn-outline-primary">
                                <Icon className="ps-1" name="pencil alternate" />
                            </button>
                            <button onClick={() => setShowModal(true)} className="btn staff-card-btn mx-1 px-2 btn-primary">
                                <Icon className="ps-1" name="trash alternate outline" />
                            </button>
                        </div>

                    </div>}{auth.role === "agent" && <div className="row my-2">
                    <div className="col-md-12">
                        <button onClick={() => open(data)} className="btn btn-sm col-12 btn-pill btn-outline-primary">
                            Assign Lead
                        </button>
                    </div>
                </div>}
                </div>
                
            </div>
                :
                <div className="staff-card col-12 row py-1">
                    <div className="col-5 staff-card-img my-2">
                        <img
                            src={img && MEDIA_URL + img}
                            className=""
                            alt=""
                            onMouseEnter={()=>setShowImageHover(true)}
                            onMouseLeave={()=>setShowImageHover(false)}
                        />
                    </div>
                    <div className="col-7 my-auto position-relative px-4">
                        {auth.role==='company'&&<ToggleButton data={data} refresh={refresh}/>}
                        <div className="row">
                            <div className="staff-card-name col-9">{user}</div>
                            <div className="staff-card-role">{role}</div>
                        </div>
                        {auth.role === "company" &&
                            <div className="row staff-card-btnholder me-1">
                                <div  className="">
                                    <button disabled={!data?.is_active} onClick={() => edit(data)}  className="btn staff-card-btn px-2 mx-1 btn-outline-primary">
                                        <Icon name="pencil alternate" />
                                    </button>
                                    <button onClick={() => setShowModal(true)} className="btn staff-card-btn mx-1 px-2 btn-primary">
                                        <Icon name="trash alternate outline" />
                                    </button>
                                </div>

                            </div>
                        }
                    </div>
                    <div className="row text-center mb-1 mx-auto">
                        <div className="col-4 ">
                                <div className="agent-card-data">{staffData.total_leads || 0}</div>
                                <div className="agent-card-label">Lead</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{staffData.leads_completed || 0}</div>
                                <div className="agent-card-label">Completed</div>
                            </div>
                            <div className="col-4 ">
                                <div className="agent-card-data">{staffData.leads_rejected || 0}</div>
                                <div className="agent-card-label">Rejected</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{staffData.total_followUps || 0}</div>
                                <div className="agent-card-label">Followups</div>
                            </div>
                            <div className="col-4 ">
                                <div className="agent-card-data">{staffData.follow_ups_completed || 0}</div>
                                <div className="agent-card-label">Completed</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{staffData.leads_closed || 0}</div>
                                <div className="agent-card-label">Closed</div>
                            </div>
                        </div>
                    {auth.role === "agent" &&

                        <div className="row my-2">
                            <div className="col-md-12">
                                <button onClick={() => open(data)} className="btn col-12 btn-pill btn-outline-primary">
                                    Assign Lead
                                </button>
                            </div>
                        </div>
                    }
                </div>}
            <Modal
                show={showModal}
                onHide={handleClose}
                centered={true}
                size="lg"
                contentClassName="agent-modal"
            >
                <Modal.Body className='p-5'>
                    <div className="">
                        <div className="row">
                            <div className="h3 fw-bolder">Delete Staff</div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row my-4">
                                    <div className="col-md-12 px-5 fs-5">
                                        Are you sure you wan't to delete agent <b className="text-primary fs-4">{user}</b> ?

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row staff-btn-container p-2">
                            <div className="row col-md-6">
                                <div className="col-md-6 p-2">
                                    <button type='button' onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</button>
                                </div>
                                <div className="col-md-6 p-2">
                                    <button type='button' onClick={(e) => handleSubmit(e)} className='btn btn-danger staff-btn col-12'>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default StaffCard