import React, { useState } from 'react'
import useLeadServices from '../../../services/leadServices'
import Swal from 'sweetalert2'
import { Form } from 'react-bootstrap'
import { DatePicker } from '@mui/x-date-pickers'

const ReminderForm = ({ id, refresh, handleCloseReminder, reschedule }) => {
    const [reminderDate, setReminderDate] = useState("")
    const [reminderNote, setReminderNote] = useState("")
    const [reminderStatus, setReminderStatus] = useState(true)

    const { postNewFollowUpReminder, putFollowUpReminder } = useLeadServices()

    const handleReminderSubmit = async (e) => {
        e.preventDefault()
        if (reschedule) {
            try {
                var datas
                if (!reminderStatus) {
                    datas = JSON.stringify({ 'is_active': false })
                }
                else if(reminderDate){
                    datas = JSON.stringify({ 'date': reminderDate })
                }
                else{
                    handleCloseReminder()
                }
                const response = await putFollowUpReminder(id, datas)
                if (response?.success) {
                    Swal.fire("", response?.message, "success")
                    setReminderDate("")
                    setReminderStatus(true)
                    handleCloseReminder()
                }
                else {
                    Swal.fire("", response?.message, "error")
                }
                refresh()
            } catch {
                Swal.fire("", "Something went wrong!!", "error");
             }
        }
        else {
            try {
                const datas = JSON.stringify(
                    {
                        'lead': id,
                        'date': reminderDate,
                        'note': reminderNote
                    }
                );
                const response = await postNewFollowUpReminder(datas)
                // console.log(response?.data)
                if (response?.success) {
                    Swal.fire("", response?.message, "success")
                    setReminderDate("")
                    setReminderNote("")
                    handleCloseReminder()
                }
                else {
                    Swal.fire("", response?.message, "error")
                }
                console.log("second")
                refresh()
            }
            catch (err){
                Swal.fire("", "Something went wrong!!", "error");
            }
        }

    }

    return (
        <Form onSubmit={handleReminderSubmit}>
            <div className="row mb-3 my-3">
                <div className="h3 fw-bold">Schedule Reminder</div>
            </div>
            <div className="row mb-3">
                <Form.Group className='' controlId='leadCourse'>
                    <Form.Label className='fw-bolder'>Date</Form.Label>
                    <DatePicker
                        label={'Next Follow Up Date'}
                        className="reminder-datepicker form-control"
                        onChange={(e) => setReminderDate(e['$d'])}
                        format="DD / MMM / YYYY"
                    />
                </Form.Group>
            </div>
            <div className="row mb-3">
                {reschedule ?
                    <Form.Group className='' controlId='leadCourse'>
                        <Form.Label className='fw-bolder'>Status</Form.Label>
                        <div
                            className={`form-control btn ${reminderStatus ? 'btn-secondary' : 'btn-success'}`}
                            onClick={() => setReminderStatus(!reminderStatus)}
                        >
                            {reminderStatus && 'Set as '}Completed
                        </div>
                    </Form.Group>

                    : <Form.Group className='' controlId='leadCourse'>
                        <Form.Label className='fw-bolder'>Notes</Form.Label>
                        <Form.Control className='py-2' placeholder="Reminder Text" onChange={(e) => setReminderNote(e.target.value)} />
                    </Form.Group>}
            </div>
            <div className="row mb-3 px-3">
                <button className="btn btn-primary btn-pill col-6">Save</button>
                <div onClick={handleCloseReminder} className="btn btn-danger btn-pill col-6">Cancel</div>
            </div>
        </Form>
    )
}

export default ReminderForm
