import React, { useReducer } from "react";
import { useEffect, useState } from "react";

import { AiOutlineSearch,AiOutlineArrowLeft,AiOutlineArrowRight } from 'react-icons/ai'
import "./Lead.css";
import plus from "../../assets/images/icons/plus-circle-fill.png";
import upload_img from "../../assets/images/icons/upload_img.png";
import check from "../../assets/images/icons/check.png";
import dummy_profile from "../../assets/images/imgs/profile_image_dummy.jpg";

import {
  Button,
  Dropdown as DropDownBootstrap,
  DropdownButton,
  Modal,
} from "react-bootstrap";
// import { Search } from 'semantic-ui-react';
import { Header, Icon, Image } from "semantic-ui-react";
import LeadCard from "./components/LeadCard";
import LeadAddForm from "./components/LeadAddForm";
import useCompanyServices from "../../services/companyServices";
import useAgentServices from "../../services/agentServices";
import useStaffServices from "../../services/staffServices";
// import useCustomerServices from '../../services/customerServices'
import useAuth from "../../hooks/useAuth";
import { DatePicker } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { Dropdown } from "semantic-ui-react";
import useLeadServices from "../../services/leadServices";
import LeadUplodForm from "../dashboard/components/LeadUplodForm";
import { Pagination } from "../../components/pagination/Pagination";
import { MEDIA_URL } from "../../api/axios";

const initialState = {
  priority: '',
  status: '',
  date: '',
  from: '', to: '',
  statusType: 'Status',
  dateType: 'Filter',
  priorityType: 'Priority',
  showSearch: false,
  showPicker: false,
  searchQuery: "",
  isSearch: false,
}
const reducer = (state, action) => {
  switch (action.type) {
    case "priority":
      return { ...state, priority: action.payload };
    case "status":
      return { ...state, status: action.payload };
    case "date":
      return { ...state, date: action.payload, from: "", to: "" };
    case "from":
      return { ...state, from: action.payload, date: "" };
    case "to":
      return { ...state, to: action.payload, date: "" };
    case "statusType":
      return { ...state, statusType: action.payload };
    case "dateType":
      return { ...state, dateType: action.payload };
    case "priorityType":
      return { ...state, priorityType: action.payload };
    case 'showSearch':
      return { ...state, showSearch: !state.showSearch };
    case 'showPicker':
      return { ...state, showPicker: action.payload };
    case 'searchQuery':
      return { ...state, searchQuery: action.payload }
    case 'isSearch':
      return { ...state, isSearch: action.payload };

    case 'reset':
      return { ...initialState };
  }
};

const Leads = () => {
  const [show, setShow] = useState(false);
  // const [check, setCheck] = useState(false)
  const [leadList, setLeadList] = useState();
  const [fieldTypeList, setFieldTypeList] = useState("");
  const [uploadModal, setUploadModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(upload_img);
  const [previewText, setPreviewText] = useState();
  const [selected, setSelected] = useState("");
  const [crntpg, setCrntpg] = useState(1);
  const [limit, setLimit] = useState(0);
  const [item, setItem] = useState(25);
  const [uploadAgent, setUploadAgent] = useState();
  const [uploadStaff, setUploadStaff] = useState();
  const [userList, setUserList] = useState("");
  // const [filter, setFilter] = useState("Filter")
  const [filters, dispatch] = useReducer(reducer, initialState);
  const { date, status, priority } = filters;

  const { auth } = useAuth();

  const { getLeadListToday } = useCompanyServices();
  const { getAssignedLeadList } = useAgentServices();
  const { getLeadList } = useStaffServices();
  const {
    getActiveFieldTypes,
    postLeadBulkUpload,
    getBulkUploadToMultipleAgents,
    postBulkUploadToMultipleAgents,
  } = useLeadServices();

  useEffect(() => {
    getFieldTypeList()
  }, []);



  useEffect(() => {
    getLeadListData();
  }, [
    filters.status, filters.priority, crntpg, filters.isSearch
  ])

  useEffect(() => {
    if (filters.from == "" && filters.to == "") {
      getLeadListData();
    }
  }, [
    filters.date
  ])

  const getFieldTypeList = async () => {
    const response = await getActiveFieldTypes();
    if (response !== null) {
      // console.log(response?.data)
      setFieldTypeList(response.data);
    }
  };

  const getLeadListData = async (type, temp, multiple) => {
    const data = {
      priority: filters.priority,
      date: filters.date,
      to: filters.to,
      from: filters.from,
      status: filters.status,
      page: crntpg,
      item: item,
      search: filters.isSearch ? filters.searchQuery : "",
    };
    var response = null;
    if (auth?.role === "company") {
      response = await getLeadListToday(data);
    } else if (auth?.role === "agent") {
      response = await getAssignedLeadList(data);
    } else {
      response = await getLeadList(data);
    }
    if (response?.success) {
      // console.log(response?.data?.leads)
      setLimit(response?.data?.total_page);
      setLeadList(response?.data?.leads);
    }
  };

  const handleSearch = () => {
    if (filters.searchQuery != "") {
      dispatch({ type: 'isSearch', payload: true })
    }
    else {
      getLeadListData()
    }
  }

  const handleDate = async (e) => {
    dispatch({ type: 'date', payload: format(e['$d'], 'yyyy-MM-dd') });
    handlePickerClose()

    // setDate(e['$d'])
    // getLeadListData("date", format(e['$d'], 'yyyy-MM-dd'))
    // console.log(e['$d'])
  };

  const handleDateRange = async (e) => {
    e.preventDefault();
    getLeadListData();
    handlePickerClose();
    // // setDate(date[0])
  };

  const handleClose = () => setShow(false);

  const handleStatus = (temp) => {
    dispatch({ type: "statusType", payload: temp });
    dispatch({ type: "status", payload: temp });

    // getLeadListData("status", temp)
  };

  const handlePriority = (temp) => {
    dispatch({ type: "priorityType", payload: temp });
    dispatch({ type: "priority", payload: temp });

    // getLeadListData("priority", temp)
  };

  const handleUploadOpen = () => setUploadModal(true);
  const handleUploadClose = () => {
    setSelected("");
    setUploadAgent("");
    setUploadStaff("");
    setUploadModal(false);
  };
  const handlePickerClose = () => {
    dispatch({ type: "showPicker", payload: false })
  };

  useEffect(() => {
    if (!selected) {
      setImagePreview(upload_img);
      setPreviewText(
        <div className="">
          <div>Drag & Drop Files Here</div>
          <div>or</div>
        </div>
      );
      return;
    }

    const objectUrl = URL.createObjectURL(selected);
    setImagePreview(check);
    setPreviewText(
      <div className="">
        <div>{selected.name}</div>
      </div>
    );

    return () => URL.revokeObjectURL(objectUrl);
  }, [selected]);

  const handleFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelected(undefined);
      return;
    }
    //console.log(e.target.files[0])
    if (auth.role !== "staff") {
      try {
        const response = await getBulkUploadToMultipleAgents();
        console.log(response?.data);
        if (response?.success) {
          const agentOptions = response?.data?.agents?.map(
            (options, index) => ({
              key: index,
              text: options.first_name,
              value: options.fkUser,
              content: (
                <Header as="h5">
                  <Image
                    circular
                    src={
                      options.image ? MEDIA_URL + options.image : dummy_profile
                    }
                  />
                  <Header.Content>
                    {options.first_name}
                    <Header.Subheader>{options.post}</Header.Subheader>
                  </Header.Content>
                </Header>
              ),
            })
          );
          const staffOptions = response?.data?.staffs?.map(
            (options, index) => ({
              key: index,
              text: options.first_name,
              value: options.fkUser,
              disabled: !options.is_assigned,
              content: (
                <Header as="h5">
                  <Image
                    circular
                    src={
                      options.image ? MEDIA_URL + options.image : dummy_profile
                    }
                  />
                  <Header.Content>
                    {options.first_name}
                    <Header.Subheader>{options.post}</Header.Subheader>
                  </Header.Content>
                </Header>
              ),
            })
          );
          setUploadAgent(agentOptions || "");
          setUploadStaff(staffOptions || "");
        }
      } catch {}
    }

    setSelected(e.target.files[0]);
  };

  const handleBulkSubmit = async (e, user_list) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("file", selected);
      data.append("user_list", user_list);
      const response = await postBulkUploadToMultipleAgents(data);
      // const response = await postLeadBulkUpload(data);
      if (response?.success) {
        if  (response?.data)  {
          const temp = response?.data;
          const renderTableData = () => {
            return temp.map((item) => {
              return `<tr border="1">
                        <td>${item.duplicate}</td>
                        <td>${item.duplicate_number}</td>
                      </tr>`;
            });
          };
          const table = `
            <h5><u>Duplicates</u></h5>
            <table border="1" style="width:100%;">
              <thead>
                <tr border="1">
                  <th>Lead</th>
                  <th>Number</th>
                </tr>
              </thead>
              <tbody>${renderTableData().join("")}</tbody>
            </table>
          `;
          Swal.fire({
            title:  response?.message,
            icon:  "success",
            html: table,
          });
        } else  {
          Swal.fire("", response?.message, "success");
        }
        getLeadListData();
        getFieldTypeList();
        handleUploadClose();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
      //console.log(err)
    }
  };

  const handleFilter = (data) => {
    // setFilter(data)
    dispatch({ type: 'dateType', payload: data });

    if (data === 'Date' || data === 'Range') {
      dispatch({ type: "showPicker", payload: true })
    }
    if (data == "Today") {
      dispatch({ type: "date", payload: format(new Date(), "yyyy-MM-dd") });

      // getLeadListData("date", format(new Date(), 'yyyy-MM-dd'))
    } else if (data == "Yesterday") {
      let date = new Date();
      date.setDate(date.getDate() - 1);

      dispatch({ type: "date", payload: format(date, "yyyy-MM-dd") });

      // getLeadListData("date", format(date, 'yyyy-MM-dd'))
    }
  };
  return (
    <div className="">
        <div className="">
          <div className="row pe-1 align-items-center">
            {/* <div id="leadsearch" className="row z-index-n1 pe-1"> */}
            {/* {auth?.role === "company" &&
            <div id="searchLead" className="col-3 me-0 pe-1 my-3">
              <div className="me-0">
              <Search
              input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                className="lead-searchBox"
                placeholder="Search Here..."
                size='large'
                fluid={true}
                
                // onResultSelect={}
                onSearchChange={handleSearch}
              />
              </div>
            </div>} */}
          <div className="col-md-4 col-10 row">
            <div className="col-md col my-3 ms-md-1 ms-3 pe-0">
              <Dropdown
                className='btn btn-sm btn-primary'
                text="Status"

              >
                <Dropdown.Menu className='mt-2 p-3'>
                  <Dropdown.Item onClick={() => handleStatus("New")}>New</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleStatus("Open")}>Open</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleStatus("Unavailable")}>Unavailable</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleStatus("Closed")}>Closed</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleStatus("Rejected")}>Rejected</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleStatus("Paused")}>Paused</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md col my-3 ms-1 ps-0 pe-0">
              <Dropdown
                className='btn btn-sm btn-primary'
                text="Priority"
              >
                <Dropdown.Menu className='mt-2 p-3'>
                  <Dropdown.Item onClick={() => handlePriority("Hot")}>Hot</Dropdown.Item>
                  <Dropdown.Item onClick={() => handlePriority("Warm")}>Warm</Dropdown.Item>
                  <Dropdown.Item onClick={() => handlePriority("Cold")}>Cold</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className=" col  ms-1 pe-0 ps-0 col-md my-3">
              <Dropdown id="dropdown-basic-button"
                className='btn btn-sm btn-primary'
                text={filters.dateType}>
                <Dropdown.Menu className='mt-2 p-3'>
                  <Dropdown.Item onClick={() => handleFilter("Today")}>Today</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleFilter("Yesterday")}>Yesterday</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleFilter("Date")}>Specific day</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleFilter("Range")}>Date Range</Dropdown.Item>
                </Dropdown.Menu></Dropdown>
            </div>

          </div>
          {(filters.priority || filters.status || filters.dateType != "Filter" || filters.isSearch) &&
            <div className="col-2 ms-1 pe-0 ps-0 col-md-1 clear-filter my-3">
              <Button
                onClick={() => dispatch({ type: "reset" })}
                variant="danger"
                size="sm"
              >
                {window.innerWidth > 768 ? "Reset" : <Icon name="x" />}
              </Button>
            </div>}


          <Modal
            show={filters.showPicker}
            onHide={handlePickerClose}
            centered
            size="sm"
            contentClassName="upload-modal"
          >
            <Modal.Body className="p-4">
              {filters.dateType === "Date" &&
                <>
                  <div className="col-md-12 my-3 ps-md-0">
                    <DatePicker
                      className='lead-datepicker col-12 px-2'
                      onChange={(e) => handleDate(e)}
                      format="DD-MMM-YYYY"
                    />
                  </div>
                </>}
              {filters.dateType === "Range" &&
                <>
                  <div className="col-md-12 my-3 ps-md-0">
                    <DatePicker
                      className='lead-datepicker col-12 px-2 '
                      onChange={(e) => dispatch({ type: 'from', payload: format(e['$d'], 'yyyy-MM-dd') })}

                      format="DD-MMM-YYYY"
                    />
                  </div>
                  <div className="col-md-12 my-3 ps-md-1">
                    <DatePicker
                      className='lead-datepicker col-12 px-2 px-md-0'
                      onChange={(e) => dispatch({ type: 'to', payload: format(e['$d'], 'yyyy-MM-dd') })}
                      format="DD-MMM-YYYY"
                    />
                  </div>

                  <div className='col-md-12 my-3 ps-md-1'>
                    <button className='btn btn-pill btn-outline-primary col-12 px-2 px-md-0' onClick={(e) => handleDateRange(e)}>search</button>
                  </div>


                </>
              }
            </Modal.Body>
          </Modal>
          {/* </div> */}
          <div id="" className={`${(filters.priority || filters.status || filters.dateType != "Filter") || filters.isSearch ? 'col-md-7' : 'col-md-8'} col-12 my-3 me-3 text-md-end align-items-center d-md-flex justify-content-end text-center`}>
            {window.innerWidth > 768 ? <>
              <div class={filters.showSearch ? "ui action input search-bar show" : "ui action input search-bar"}>
                <input value={filters.searchQuery} onChange={(e) => dispatch({ type: "searchQuery", payload: e.target.value })} type="text" placeholder="Search..." class="rounded-input" />
                <button onClick={handleSearch} class="ui button rounded-button"> <AiOutlineSearch/></button>
              </div>

              <div className="col-4 ms-1 me-2 pe-0 ps-0 col-md-1 clear-filter my-3">
                <Button className='search-trigger col-12' onClick={() => dispatch({ type: 'showSearch' })} variant={filters.showSearch ? "danger" : "primary"} size='sm'>
                  {/* <Icon name={filters.showSearch ? 'arrow right' : 'search'} size='' /> */}
                 {filters.showSearch ? <AiOutlineArrowRight/> : <AiOutlineSearch/>}
                </Button>
              </div>
            </>
              :
              <div class={"ui action input search-bar show mb-3"}>
                <input value={filters.searchQuery} onChange={(e) => dispatch({ type: "searchQuery", payload: e.target.value })} type="text" placeholder="Search..." class="rounded-input" />
                <button onClick={handleSearch} class="ui button rounded-button"><AiOutlineSearch /></button>
              </div>
            }
            <div
              onClick={handleUploadOpen}
              id="addLead"
              className="ui circular button text-bg-primary"
            >
              <Icon name="cloud upload" />
              Lead Bulk Upload
            </div>

            <Modal
              show={uploadModal}
              onHide={handleUploadClose}
              centered
              size="lg"
              contentClassName="upload-modal"
            >
              <Modal.Body className="p-4">
                <LeadUplodForm
                  {...{
                    handleFile,
                    uploadAgent,
                    uploadStaff,
                    imagePreview,
                    handleBulkSubmit,
                    handleUploadClose,
                    previewText,
                  }}
                />
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <div className="row mt-3 ms-1 mb-3 me-0">
          <div className="col-lg-12">
            {window.innerWidth > 768 && (
              <div className="lead-title-card col-12 row p-3 pe-4 me-0">
                <div className="col-1 ps-2 pe-0 p-2 lead-card-head serial-number">
                  Sl.
                  <br />
                  no
                </div>
                <div className="col-1 p-2 lead-card-head px-0">Name</div>
                <div className="col-2 lead-card-head p-2 ps-1">Phone number</div>
                <div className="col-3 lead-card-head p-2 px-0">E-mail</div>
                <div className="col-1 lead-card-head p-2 px-0">Created</div>
                <div className="col-1 lead-card-head p-2 ps-2">Source</div>
                <div className="col-1 lead-card-head p-2 px-0">Interested</div>
                <div className="col-1 lead-card-head p-2 ps-2">Location</div>
                <div className="col-1 lead-card-head p-2 ps-1  pe-5">Status</div>
                {/* <div className="col-1 lead-card-head p-2 pe-4 text-center">Desc</div> */}
              </div>
            )}
          </div>
        </div>
        <div className="row lead-container">
          {leadList &&
            leadList.map((data, index) => {
              return (
                <div key={index} className="row lead-data ms-1 me-2 ">
                  <LeadCard
                    auth={auth}
                    index={(item * (crntpg - 1)) + index + 1}
                    data={data}
                    updateLeads={getLeadListData}
                    getFieldTypeList={getFieldTypeList}
                    fieldTypeList={fieldTypeList}
                  />
                </div>
              );
            })}
        </div>
        {/* <div className='row'>
        <div className='col-md-5'>
          
        </div>
        <div className='col-md-1 p-3' hidden>
          <i className='btn btn-outline-primary'><Icon name='arrow left' size='big' /></i>
        </div>
        <div className='col-md p-3'>
          <span className='btn btn-primary'><span className='next-page'>Next </span><Icon name='arrow right' size='big' className='' /></span>
        </div>
      </div> */}
        {/* {auth.role === "company" && */}
        <div className=" ">
          <div
            onClick={() => setShow(true)}
            className={`button lead-add-btn ${window.innerWidth > 768 ? ' bottom-0 ' : ' mobile-view '}`}
          >
            <img src={plus} className=" lead-plus-button" alt="" />
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            contentClassName="lead-modal"
          >
            <Modal.Body className="p-5">
              <LeadAddForm handleClose={handleClose} refresh={getLeadListData} />
            </Modal.Body>
          </Modal>
        </div>
        {/* } */}
      </div>
      {limit > 1 && <Pagination {...{ crntpg, setCrntpg, limit }} />}
    </div>
  );
};

export default Leads;
