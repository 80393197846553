import { useEffect, useState } from "react"
import add_img from "../../assets/images/icons/add_img.png"


import "./Register.css"
import banner from "../../assets/images/imgs/login_banner.jpg"
import RegisterForm from "./components/RegisterForm"
import { companyRegister } from "../../services/baseServices"
import { Navigate, useNavigate } from "react-router-dom"
import {companyCheck} from "../../services/baseServices"
import Swal from "sweetalert2"

const Register = () => {
  const [companyName, setCompanyName] = useState("")
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [mobile, setMobile] = useState("")
  const [email, setEmail] = useState("")
  const [profileImg, setProfileImg] = useState("")
  const [hideForm, setHideForm] = useState(false)

  const [imagePreview, setImagePreview] = useState(add_img)

  const navigate = useNavigate()

  useEffect(() => {
    if (!profileImg) {
      setImagePreview(add_img)
      return
    }

    const objectUrl = URL.createObjectURL(profileImg)
    setImagePreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [profileImg])

  const handleImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setProfileImg(undefined)
      return
    }

    setProfileImg(e.target.files[0])
  }

  const handleSubmit = async (e) => {

    e.preventDefault()
    if (password === passwordConfirm) {
      try {
        const data = new FormData()
        // const data = JSON.stringify({
          data.append("company_name", companyName)
          data.append("username", userName)
          data.append("password", password)
          data.append("mobile", mobile)
          data.append("email", email)
          data.append("logo",profileImg)

        // });
        const response = await companyRegister(data)
        if(response?.success) {
          Swal.fire("", response?.message, "success")
          navigate('/login')
        }
        else{
          Swal.fire("", response?.message, "error")
        }
      } catch (err) {
        Swal.fire("", "Network Error","error")
      }
    }
  }

  useEffect(()=>{
    getCompanyCheck()
  },[])

  const getCompanyCheck = async () =>{
    try{
      const response = await companyCheck()
      // console.log(response?.data?.exist)
      if (response?.success){
        setHideForm(response?.data?.exist)
      }
      else{
        Swal.fire("", "Some errors occured in the network", "error")
      }
    }
    catch(err)
    {
      // console.error(err)
    }
  }

  return (
    !hideForm?
    <div className="container-fluid pt-5">
      <div className="row pt-5 mb-5">
        <div className="col-md-5 pe-md-3 pe-5">
          <div className="m-auto ms-5 p-md-5 p-0 pt-0">
            <div className="h3 login-title mb-3">Sign Up</div>
            <div className="h5 login-subtitle mb-5">Welcome! Please enter your credentials to register</div>
            <RegisterForm
              {...{
                handleSubmit, setCompanyName,
                setUserName, setPassword,
                setPasswordConfirm,
                setMobile, setEmail, handleImage, imagePreview
              }}
            />
          </div>
        </div>
        {window.innerWidth>768&&<div className="col-md-7 p-0 ps-5">
          <div className="register-banner">
            <img className="w-100" src={banner} alt="" />
          </div>
        </div>}
      </div>
    </div>
    :
  <Navigate to="/" />
  )
}

export default Register