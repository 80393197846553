import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2'
import useCompanyServices from '../../../services/companyServices'

const LeadEditForm = (props) => {
    const {handleClose, data, refresh} = props

    const [name, setName] = useState(data?.name)
    const [mobile, setMobile] = useState(data?.mobile)
    const [email, setEmail] = useState(data?.email)
    const [course, setCourse] = useState(data?.interest)

    const { putLeadDetailsToday } = useCompanyServices()

    const handleSubmit = async(e) => {
        e.preventDefault()
        try{
            const datas = JSON.stringify({
                name: name,
                mobile: mobile,
                email: email,
                interest: course
            })
            const response = await putLeadDetailsToday(data?.id, datas)
            if (response?.success){
                refresh()
                Swal.fire("", response?.message, "success")
                handleClose()
            }
            else{
                Swal.fire("", response?.message, "error")
            }
        }
        catch(err){
            Swal.fire("", "Something went wrong!!", "error");
        }
    }

    return (
        <div className="">
            <div className="row">
                <div className="h3 fw-bolder">Edit Lead</div>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row my-4">
                            <div className="col-md-8 px-1">
                                <Form.Group className='' controlId='leadName'>
                                    <Form.Label className='fw-bolder'>Name</Form.Label>
                                    <Form.Control required className='py-2' value={name} placeholder="Beserac" onChange={(e)=>setName(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-md-4 px-1">
                                <Form.Group className='' controlId='leadMobile'>
                                    <Form.Label className='fw-bolder'>Mobile Number</Form.Label>
                                    <Form.Control required className='py-2' value={mobile} placeholder="1234567890" onChange={(e)=>setMobile(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-7 px-1">
                                <Form.Group className='' controlId='leadMail'>
                                    <Form.Label className='fw-bolder'>Email</Form.Label>
                                    <Form.Control required className='py-2' value={email} placeholder="user@example.com" onChange={(e)=>setEmail(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-5 px-1">
                                <Form.Group className='' controlId='leadCourse'>
                                    <Form.Label className='fw-bolder'>Course</Form.Label>
                                    <Form.Control required className='py-2' value={course} placeholder="Course name" onChange={(e)=>setCourse(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row staff-btn-container">
                    <div className="row col-md-6">
                        <div className="col-md-6">
                            <div onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</div>
                        </div>
                        <div className="col-md-6">
                            <button type='submit' className='btn btn-primary staff-btn col-12'>Save</button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default LeadEditForm