import './Layout.css'
import { useEffect, useState } from "react"
import { Navigate, Outlet } from "react-router-dom"
import Header from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import useAuth from '../../hooks/useAuth'
// import SideNavbar from "../sidebar/Sidebar"

export const Layout = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [style,setStyle]  = useState("");

    const { auth } = useAuth()

    useEffect(()=>{
        auth?.role==="company" || auth?.role==="agent"?setStyle("company"):setStyle("agent")
    })


    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 768) {
            setIsSidebarOpen(false);
          } else {
            setIsSidebarOpen(true);
          }
        };
    
        handleResize();
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    return (
      auth.accessToken?
        <div className="container-fluid">
            <div className={`row main ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <Sidebar isOpen={isSidebarOpen} auth={auth} onClose={() => setIsSidebarOpen(false)} />
                <div className={"main-content col mt-3 px-md-3 px-0 "+style}>
                    <Header toggle={handleSidebarToggle}/>
                    <div className='content mt-3 ms-md-5 px-2 px-md-0'>
                        <Outlet />
                    </div>

                </div>
            </div>
        </div>
        :
        <Navigate to="/login" />
    )
}
