import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Checkbox } from 'semantic-ui-react'

const StaffItem = (
    { 
        data, 
        index, 
        currentAgent, 
        handleAssignStaff, 
        selected 
    }
) => {
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        setChecked(data.is_assigned)
    }, [data])
    const handleAssign = (value) => {
        const assign = handleAssignStaff(data.id)
        if(assign) {
          setChecked(value)  
        }
    }

    const handleCheck = (e) => {
        // setChecked()
        handleAssign(!checked)
    }
    // console.log(data)
    return (
        <div className="row py-2 assign-table-content my-1">
            <div className="col-2">{index+1}</div>
            <div className="col-4">{data.first_name}</div>
            <div className="col-4">{data.mobile}</div>
            <div className="col-2">
                <Checkbox toggle checked={checked} onChange={handleCheck} disabled={selected==='assigned'||!checked?false:true}/>
            </div>
        </div>
    )
}

export default StaffItem
