import axios from "../api/axios";
import useAxios from "../hooks/useAxios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

//check if company exists or not
export const companyCheck = async () => {
    try {
        const response = await axios.get('/accounts/company_check/')
        return response.data
    }
    catch (err) {
        throw new Error("Company Already Exists")
    }
};

//register new company
export const companyRegister = async (data) => {
    try {
        const response = await axios.post('/accounts/company_register/', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return response.data

    } catch (err) {
        throw new Error("Company Registration fialed")
    }
};

// //login api
// export const login = async (data) => {

//     try {
//         const response = await axios.post('/accounts/login/', data)
//         return response.data

//     } catch (err) {
//         throw new Error("Login fialed")
//     }
// };

//logout api
export const logout = async (data) => {
    try {
        const response = await axios.post('/accounts/logout/', data)
        return response.data

    } catch (err) {
        throw new Error("Login fialed")
    }
};

const useBaseServices = () => {
    const axiosPrivate = useAxiosPrivate()
    const axios = useAxios()


    //login api
    const login = async (data) => {

        try {
            const response = await axios.post('/accounts/login/', data)
            return response.data

        } catch (err) {
            throw new Error("Login fialed")
        }
    };

    //get staff or agent details at first login
    const getProfileDetails = async () => {
        const response = await axiosPrivate.get("/accounts/set_first_password/")
        return response.data
    }

    //edit staff or agent details at first login
    const putProfileDetails = async (data) => {
        const response = await axiosPrivate.put("/accounts/set_first_password/", data)
        return response.data
    }

    //register new staff
    const postStaffRegister = async (data) => {
        const response = await axiosPrivate.post("/accounts/staff_register/", data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return response.data
    }

    //register new agent
    const postAgentRegister = async (data) => {
        const response = await axiosPrivate.post("/accounts/agent_register/", data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return response.data
    }

    //change password
    const putPasswordChange = async (data) => {
        const response = await axiosPrivate.put("/accounts/change_password/", data)
        return response.data
    }

    //get staff or agent profile details
    const getStaffProfile = async () => {
        const response = await axiosPrivate.get("/accounts/profile/")
        return response.data
    }

    //edit staff or agent profile details
    const putStaffProfile = async (data) => {
        const response = await axiosPrivate.put("/accounts/profile/", data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return response.data
    }

    //get all agent numbers
    const getAllAgentNumbers = async () => {
        const response = await axiosPrivate.get("/accounts/extension/create/")
        return response.data
    }

    //add new agent number and extension
    const postNewAgentNumber = async (data) => {
        const response = await axiosPrivate.post("/accounts/extension/create/", data)
        return response.data
    }

    //Edit Agent Number details
    const putEditAgentNumber = async (id, data) => {
        const response = await axiosPrivate.put("/accounts/extension/edit/" + id + "/", data)
        return response.data
    }

    //delete Agent Number
    const deleteAgentNumber = async (id) => {
        const response = await axiosPrivate.delete("/accounts/extension/edit/" + id + "/")
        return response.data
    }

    //Change Agent Number Status
    const putChangeAgentNumberStatus = async (id) => {
        const response = await axiosPrivate.put("/accounts/extension/status/" + id + "/")
        return response.data
    }

    //get all Active and unassigned agent numbers
    const getActiveAgentNumbers = async () => {
        const response = await axiosPrivate.get("/accounts/extension/active/")
        return response.data
    }

    //get assigned agent number (company access) (fkuser id)
    const getAssignAgentNumber = async (id) => {
        const response = await axiosPrivate.get("/accounts/extension/assign/" + id + "/")
        return response.data
    }

    //assign new agent number and extension
    const postAssignAgentNumber = async (id, data) => {
        const response = await axiosPrivate.post("/accounts/extension/assign/" + id + "/", data)
        return response.data
    }

    //unassign Agent Number
    const deleteUnassignAgentNumber = async (id) => {
        const response = await axiosPrivate.delete("/accounts/extension/assign/" + id + "/")
        return response.data
    }

    //get assigned agent number (all users)
    const getAssignedAgentNumber = async (id) => {
        const response = await axiosPrivate.get("/accounts/extension/assigned/")
        return response.data
    }

    //returns
    return {
        //get requests
        getProfileDetails,
        getStaffProfile,
        getAllAgentNumbers,
        getActiveAgentNumbers,
        getAssignAgentNumber,
        getAssignedAgentNumber,
        //post requests
        postStaffRegister,
        postAgentRegister,
        postNewAgentNumber,
        postAssignAgentNumber,
        login,
        //put requests
        putProfileDetails,
        putPasswordChange,
        putStaffProfile,
        putEditAgentNumber,
        putChangeAgentNumberStatus,
        //delete requests
        deleteAgentNumber,
        deleteUnassignAgentNumber,
    }
}
export default useBaseServices