import React from 'react'
import '../Admin.css'
import { MdAddCircle } from 'react-icons/md'

const AdminCompanyList = () => {
  return (
    <div className='company-list-container'>
      <div className="company-list-card">
        <div className="company-list-header row mx-0">
          <div className="text col-6 px-0">Company List</div>
          <div className="buttons col-6 px-0">
            <div className="company-add-btn ">
              <MdAddCircle size='22px' />&nbsp;
              Add New
            </div>
          </div>
        </div>
        <div className="company-list-table row mx-0">
          <div className="title border-bottom row mx-0 px-0 mb-1">
            <div className="col-1 px-0">Sl.no</div>
            <div className="col-2 px-0">Name</div>
            <div className="col-1 px-0">Code</div>
            <div className="col-2 px-0">Route Link</div>
            <div className="col-1 col-2 px-0">Identity</div>
            <div className="col-1 col-2 px-0">Icon</div>
            <div className="col-1 px-0">Type</div>
            <div className="col-2 px-0 text-center">Action</div>
          </div>
          <div className="content row mx-0 px-0">
            <div className="item row mx-0 px-0 my-1">
              <div className="col-1 px-3">1</div>
              <div className="col-2 px-0">Name</div>
              <div className="col-1 px-0">Code</div>
              <div className="col-2 px-0">Route Link</div>
              <div className="col-1 col-2 px-0">Identity</div>
              <div className="col-1 col-2 px-0">Icon</div>
              <div className="col-1 px-0">Type</div>
              <div className='col-2 row mx-0 text-center'>
                <div className="col-6">
                  <div className="edit-btn">
                    Edit
                  </div>
                </div>
                <div className="col-6">
                  <div className="delete-btn">
                    Delete
                  </div>
                </div>
              </div>
            </div>
            <div className="item row mx-0 px-0">
              <div className="col-1 px-3">1</div>
              <div className="col-2 px-0">Name</div>
              <div className="col-1 px-0">Code</div>
              <div className="col-2 px-0">Route Link</div>
              <div className="col-1 col-2 px-0">Identity</div>
              <div className="col-1 col-2 px-0">Icon</div>
              <div className="col-1 px-0">Type</div>
              <div className='col-2 row mx-0 text-center'>
                <div className="col-6">
                  <div className="edit-btn">
                    Edit
                  </div>
                </div>
                <div className="col-6">
                  <div className="delete-btn">
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminCompanyList
