import React from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2';
import useCustomerServices from '../../../services/customerServices';
import upload_img from "../../../assets/images/icons/upload_img.png"
import check from "../../../assets/images/icons/check.png"
import { useState } from 'react';
// import { MEDIA_URL } from '../../../api/axios';

const DocumentUploadForm = (props) => {
    const { handleClose, id, doc, refresh} = props
    const [selected, setSelected] = useState("")
    const [objectUrl, setObjectUrl] = useState("")

    const { postAddDocument } = useCustomerServices()

    function handleDragOver(event) {
        event.preventDefault();
    }

    function handleDrop(event) {
        event.preventDefault();
        var files = event.dataTransfer.files;
        var fileInput = event.target;
        fileInput.files = files;
        // console.log(event)
        handleFile(event);
    }

    const handleFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
          setSelected(undefined)
          return
        }
        setSelected(e.target.files[0])
        setObjectUrl(e.target.files[0].name)
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(selected)
        try {
          const data = new FormData()
          data.append("type", doc)
          data.append("file", selected)
          data.append("status", "Collected")
          const response = await postAddDocument(id, data)
          if(response?.success) {
            Swal.fire("","Uploaded Successfully","success")
            handleClose()
            refresh()
          }
          else{
            Swal.fire("","Upload Failed","error")
          }
        } catch (err) {
            Swal.fire("", "Something went wrong!!", "error");
        }
    }

    return (
        <div className='mx-3'>
            <Form onSubmit={handleSubmit}>
                <div className="row my-2">
                    <div className="h4 fw-bolder">New Upload</div>
                </div>
                <div className="row my-2">
                    <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className="lead-image-upload text-center p-4">
                        {objectUrl?
                        <>
                        <div className="mb-3">
                            <img className='uploadPreview' src={check} alt="" />
                        </div>
                        <div className="">
                            <div>{objectUrl}</div>
                        </div>
                        </>:
                        <>
                            <div className="mb-3">
                                <img className='uploadPreview' src={upload_img} alt="" />
                            </div>
                            <div className="">
                                <div>Drag & Drop Files Here</div>
                                <div>or</div>
                            </div>
                        </>
                        }
                        <div className="mt-3">
                            <div>
                                <label htmlFor='leadImage' className='col-6 btn btn-outline-primary browse-btn'>Browse Files</label>
                                <input id='leadImage' onChange={(e) => handleFile(e)} hidden className='form-control' type="file" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="px-0    ">
                        <div onClick={handleClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                        <button onClick={handleSubmit} className='btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3'>Submit</button>
                    </div>
                </div>
            </Form >
        </div >
    )
}

export default DocumentUploadForm