import React from 'react'
import { Form } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import banner from "../../../assets/images/imgs/login_banner.jpg"
import useBaseServices from '../../../services/baseServices'
import { MEDIA_URL } from '../../../api/axios'
import Swal from 'sweetalert2'

const PasswordChangeForm = (props) => {
    const {handleClose} = props
    const [userName, setUserName] = useState()
    const [passWord, setPassword] = useState()
    const [passWord2, setPassword2] = useState()
    const [profileDetails, setProfileDetails] = useState("")

    const {getProfileDetails, putProfileDetails} = useBaseServices()

    const getProfile = async () => {
        const response = await getProfileDetails()
        //console.log(response?.data)
        setProfileDetails(response.data)
    }

    useEffect(() => {
        getProfile()
      }, [])



    const handleUpdate = async (e) => {
        e.preventDefault()
    
        try {
            const data = JSON.stringify({
                username: userName,
                password: passWord,
                password2: passWord2,
            });
            const response = await putProfileDetails(data)
            //console.log(response?.data)
            if (response?.success){
                Swal.fire("",response?.message,"success")
                handleClose()
            }
            else{
                Swal.fire("",response?.message,"error")
            }
            
        }
        catch (err) {
            Swal.fire("", "Something went wrong!!", "error");
            //console.log(err)
        }
      }

    return (
        <div className="">
            <div className="row">
                <div className="h3 fw-bolder">Personal Profile Settings</div>
            </div>
            <Form onSubmit={handleUpdate}>
                <div className="row">
                    {window.innerWidth>768&&<div className='col-md-6 pe-5 pt-2'>
                        <div className="password-banner">
                            <img className="w-100" src={banner} alt="" />
                        </div>
                    </div>}
                    <div className="col-md-6 ps-5">
                        <div className="row mt-2 m-0 pe-5 ps-5">
                            <div className="col-md-6 ps-md-5 pe-md-3 ps-4 pe-5">
                            <label className='adduser-img col-12'>
                                <img src={profileDetails.image && MEDIA_URL+profileDetails.image} className={"w-100"} alt="" />
                            </label>
                            </div>
                            <div className="col-md-6 px-md-1">
                               <div className='row px-1 align-items-end h-50 name'>{profileDetails.first_name && profileDetails.first_name}</div> 
                               <div className='row px-1 align-items-center post'>{profileDetails.post && profileDetails.post}</div> 
                            </div>
                        </div>
                        <div className="row my-4 pe-5">
                            <div className="col-md-12 px-1 p-2 ps-md-5 ps-3">
                                <Form.Group className='' controlId=''>
                                    <Form.Label className='label-titles'>Name</Form.Label>
                                    <Form.Control className='py-2' placeholder={profileDetails.first_name && profileDetails.first_name} disabled />
                                </Form.Group>
                            </div>
                            {/* <div className="col-md-12 px-1 p-2 ps-md-5 ps-3">
                                <Form.Group className='' controlId=''>
                                    <Form.Label className='label-titles'>User Name</Form.Label>
                                    <Form.Control className='py-2' placeholder="besarac02" onChange={(e)=>setUserName(e.target.value)}/>
                                </Form.Group>
                            </div> */}
                            <div className="col-md-12 px-1 p-2 ps-md-5 ps-3">
                                <Form.Group className='' controlId=''>
                                    <Form.Label className='label-titles'>Password</Form.Label>
                                    <Form.Control className='py-2' placeholder="********************" type='password' onChange={(e)=>setPassword(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-md-12 px-1 p-2 ps-md-5 ps-3">
                                <Form.Group className='' controlId=''>
                                    <Form.Label className='label-titles'>Confirm Password</Form.Label>
                                    <Form.Control className='py-2' placeholder="********************" type='password' onChange={(e)=>setPassword2(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                    
               
                <div className="row staff-btn-container pe-5">
                    {/* <div className="row col-md-6"> */}
                        <div className='col-md-6'></div>
                        <div className="col-md-3 col-6">
                            <div onClick={handleClose} className='btn btn-primary staff-btn col-12'>Cancel</div>
                        </div>
                        <div className="col-md-3 col-6">
                            <button className='btn btn-primary staff-btn col-12'>Save</button>
                        </div>
                    {/* </div> */}
                </div></div></div>
            </Form>
        </div>
    )
}

export default PasswordChangeForm