import React, { useEffect } from "react";
import { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./pagination.css";

export const Pagination = (props) => {
  const { crntpg, setCrntpg, limit } = props;
  const [crntCstm, setCrntCstm] = useState(1);

  let num = [];
  for (let i = 1; i <= limit; i++) {
    num.push(i);
  }

  const [pageMove, setpageMove] = useState(num.slice(0, 4));
  const [pageValue, setPageValue] = useState(crntpg);

  const prevPage = (x) => {
    if (crntpg > 1) {
      x = x - 1;
      setCrntpg(x);
    }
  };

  const nextPage = (x) => {
    // console.log(num.length);
    if (crntpg < num.length) {
      x = parseInt(x, 10);
      x = x + 1;
      setCrntpg(x);
    }
  };

  // console.log(pageMove)
  // console.log('crntpg: '+crntpg)
  // console.log("crntCstm: "+crntCstm)
  const customPage = (x) => {
    let page = 1;
    switch (x) {
      case 1:
        if (crntCstm === 1) {
          page = 1;
          setpageMove(num.slice(page, 4));
        } else {
          page = crntpg > 1 ? crntpg - 1 : crntpg;
          page > 1
            ? setpageMove(num.slice(page - 1, page + 3))
            : setpageMove(num.slice(page, page + 4));
        }
        break;
      case 2:
        if (crntCstm === 1) {
          page = 2;
        } else {
          page = crntpg;
        }
        break;
      case 3:
        if (crntCstm === 1) {
          page = crntpg + 2;
          setpageMove(num.slice(page - 1, page + 3));
        } else {
          page = crntpg < num[num.length - 1] ? crntpg + 1 : crntpg;
          setpageMove(num.slice(page - 1, page + 3));
        }
        break;
      case 4:
        if (crntCstm === 1) {
          page = crntpg + 3;
          setpageMove(num.slice(page - 1, page + 3));
        } else {
          page = crntpg < num[num.length - 1] ? crntpg + 2 : crntpg;
          setpageMove(num.slice(page - 1, page + 3));
        }
        break;
      // case 1 : page = crntCstm === 2 ? crntpg -1 : crntCstm === 3 ? crntpg -2 : crntCstm === 4 ? crntpg -3 : 1 ; break;
      // case 2 : page = crntCstm === 1 ? crntpg +1 : crntCstm === 3 ? crntpg -1 : crntCstm === 4 ? crntpg -2 : 2 ; break;
      // case 3 : page = crntCstm === 1 ? crntpg +2 : crntCstm === 2 ? crntpg +1 : crntCstm === 4 ? crntpg -1 : 3 ; break;
      // case 4 : page = crntCstm === 1 ? crntpg +3 : crntCstm === 2 ? crntpg +2 : crntCstm === 3 ? crntpg +1 : 4 ; break;
      default:
        return x;
    }
    // console.log(page)
    setCrntpg(page);
  };

  useEffect(() => {
    if (crntpg > 1) {
      setpageMove(num.slice(crntpg - 2, crntpg + 2));
      setCrntCstm(2);
    } else if (crntpg < limit || crntCstm === 1) {
      setpageMove(num.slice(crntpg - 1, crntpg + 3));
      setCrntCstm(1);
    }
    // else if(crntpg === null){
    //   setpageMove(num.slice(crntpg-1,crntpg+3))
    //   setCrntCstm(1)
    // }
  }, [crntpg, limit]);

  const handleGoTo = (e) => {
    if (
      e.target?.value <= limit &&
      e.target?.value >= 1 &&
      e.target?.value !== null
    ) {
      setPageValue(e.target.value);
    } else {
      setPageValue(crntpg);
    }
  };

  return (
    <div className="page-indicator">
      {/* <div className='pagein-cont'> */}
      <div className="pagenum-container pb-3">
        <div
          onClick={() => prevPage(crntpg)}
          className="arr-btn d-flex flex-row px-2"
        >
          <div className="arr d-flex">
            <IoIosArrowBack />
          </div>
          <div className="arr-text">Prev</div>
        </div>
        <div className="num-btn px-3">
          <div>
            <div
              onClick={() => customPage(1)}
              className={crntCstm === 1 ? "btn bt-txt3" : "btn bt-txt"}
            >
              {pageMove[0]}
            </div>
            <div
              onClick={() => customPage(2)}
              className={crntCstm === 2 ? "btn bt-txt3" : "btn bt-txt"}
            >
              {pageMove[1]}
            </div>
            {pageMove.length >= 3 && (
              <div onClick={() => customPage(3)} className="btn bt-txt">
                {pageMove[2]}
              </div>
            )}
            {pageMove.length == 4 && (
              <div onClick={() => customPage(4)} className="btn bt-txt">
                {pageMove[3]}
              </div>
            )}
          </div>

          {/* <div><div onClick={()=>customPage(1)} className="btn bt-txt">{crntpg-1}</div>
      <div onClick={()=>customPage(2)} className="btn bt-txt">{crntpg}</div>
      <div onClick={()=>customPage(3)} className="btn bt-txt">{crntpg+1}</div>
      <div onClick={()=>customPage(4)} className="btn bt-txt">{crntpg+2}</div></div> */}
        </div>
        <div
          onClick={() => nextPage(crntpg)}
          className="arr-btn d-flex flex-row pt-0 px-3"
        >
          <div className="arr-text">Next</div>
          <div className="arr d-flex">
            <IoIosArrowForward />
          </div>
        </div>
      </div>
      <div className="pagenum-container mx-2 pb-3">
        <div className="d-flex flex-row p-1 px-2">
          <div
            onClick={() => {
              setCrntpg(pageValue);
            }}
            className="bt-txt2 btn px-1"
          >
            Go to
          </div>
          <form>
            <input
              onChange={handleGoTo}
              /* value={pageValue} */ type="number"
            />
          </form>
          <div className="limit-text">/{limit}</div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
