import { useEffect, useState } from 'react'
import "./CallLog.css"

import useCompanyServices from '../../services/companyServices'
import useLeadServices from '../../services/leadServices'
import useAuth from '../../hooks/useAuth'
import LogItem from './components/LogItem'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { Nav, Tab } from 'react-bootstrap'
import { Pagination } from "../../components/pagination/Pagination";

const CallLog = () => {
  const [callLogList, setCallLogList] = useState()
  const [key, setKey] = useState("tab1")
  const [crntpg, setCrntpg] = useState(1)
  const [limit, setLimit] = useState(0)
  const [item, setItem] = useState(25)

  const { auth } = useAuth()

  const { getFollowUpList } = useCompanyServices()
  const { getCallLogs } = useLeadServices()

  const axios = useAxiosPrivate()

  useEffect(() => {
    let type = key == "tab2" ? "outgoing" : "incoming"
    getCallLogList(type)
  }, [crntpg])

  const getCallLogList = async (calltype) => {
    const type = calltype == "outgoing" ? "outgoing" : "incoming"
    var form = {}
    form.type = type
    form.page = crntpg
    var response = null
    response = await getCallLogs(form)

    if (response !== null) {
      //console.log(response)
      setLimit(response?.data?.total_page)
      setCallLogList(response?.data?.content)
    }
  }

  // const handleSearch = () => {}
  return (
    <div className="">
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>

        <div className="row">
          <div className="col-12">
            <Nav variant="pills" className="flex-md-row flex-column">
              <Nav.Item className='pt-2 px-md-3'>
                <Nav.Link onClick={() => getCallLogList("incoming")} className='py-3  px-md-4 nav-panes' eventKey="tab1">Incoming</Nav.Link>
              </Nav.Item>
              <Nav.Item className='pt-2 px-md-3'>
                <Nav.Link onClick={() => getCallLogList("outgoing")} className='py-3  px-md-4 nav-panes' eventKey="tab2">Outgoing</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-12 pt-3 py-0">

            <div className="row mt-3 mb-3 me-0">
              <div className="col-lg-12">
                {window.innerWidth > 768 &&
                  <div className="followup-title-card col-12 row p-3 pe-0">
                    <div className="ps-2 pe-0 p-2 followup-card-head serial-number call">Sl.<br />no</div>
                    <div className="col-1 followup-card-head p-2 pe-5 ps-0">Date</div>
                    <div className="col-1 col-2 p-2 followup-card-head ps-0 pe-1">Client Name</div>
                    <div className="col-1 followup-card-head p-1 px-0">Client number</div>
                    <div className="col-1 col-2 p-1 followup-card-head px-0">Agent Name</div>

                    <div className="col-1 followup-card-head p-2 px-0">Start time</div>
                    <div className="col-1 followup-card-head p-2 px-1">End Time</div>

                    <div className="col followup-card-head p-2 px-0">Time</div>
                    <div className="col-1 followup-card-head p-2 ps-0">Status</div>
                    <div className="col-3 followup-card-head p-2 ps-0">Recordings</div>
                  </div>}
              </div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="tab1" className="">
                <div className="followup-container">
                  {callLogList && callLogList.map(
                    (data, index) => {
                      return (
                        <div key={index} className="row followup-data me-0">
                          <LogItem index={(item*(crntpg-1))+index+1} data={data}  />

                        </div>
                      )
                    }
                  )
                  }
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tab2" className="">
                <div className="followup-container">
                  {callLogList && callLogList.map(
                    (data, index) => {
                      return (
                        <div key={index} className="row followup-data me-0">
                          <LogItem  index={(item*(crntpg-1))+index+1} data={data} />
                        </div>
                      )
                    }
                  )
                  }
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
      {limit>1&&<Pagination {...{crntpg,setCrntpg,limit}}/>}
    </div>
  )
}

export default CallLog