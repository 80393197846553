import React, { useState } from "react";
import { ButtonToolbar, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { Crop } from "../../../components/imageCrop/Crop";
import useAuth from "../../../hooks/useAuth";
import ExtensionForm from "../../../components/forms/ExtensionForm";
import {BsPersonFillLock} from "react-icons/bs"
import { PassChange } from '../../../components/passChange/PassChange'

const StaffAddForm = (props) => {
    const { auth } = useAuth();
    const { handleImage, imageData, handleSubmit, handleClose } = props;
    const {
        name,
        setName,
        post,
        setPost,
        mobile,
        setMobile,
        email,
        setEmail,
        setResult,
        result,
        setImageData,
        setImageName,
        srcImg,
        setSrcImg,
        add_img,
        userID,
    } = props;

    const [showImageEdit, setShowImageEdit] = useState(false);
    const [numberModal, setNumberModal] = useState(false);
    const [showPC, setShowPC] = useState(false)

    const closeImagemodel = () => {
        setShowImageEdit(false);
        setResult("");
        setSrcImg("");
        setImageData("");
        setImageName("");
    };

    const handleImageSubmit = (e) => {
        e.preventDefault();
        // Swal.fire("", "Image added !!!", "success")
        setShowImageEdit(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        console.log(event.target.files[0]);
        event.preventDefault();
        var files = event.dataTransfer.files;
        var fileInput = event.target;
        fileInput.files = files;
        // console.log(event)
        handleImage(event);
    };
    const handleNumberClose = () => {
        setNumberModal(false);
    };

    const popoverHoverFocus = (
        <Popover style={{
            "backgroundColor":"grey",
            "borderColor":"#light","color":"#ffffff",
            "borderRadius":"0.5rem","padding":"0.2rem 0.5rem", "marginTop":"0.1rem"}}>
            Change Password
        </Popover>
    );

    // const handleProfile = (e) =>{
    //     if (!e.target.files || e.target.files.length === 0) {
    //       setImageData(undefined)
    //       return
    //     }
    //     setSrcImg(URL.createObjectURL(e.target.files[0]))
    //     setImageName(e.target.files[0].name)
    //     setImageData(e.target.files[0])
    //     setResult(e.target.files[0])
    // }

    return (
        <div className="">
            <div className="row">
                <div className="h3 row fw-bolder" style={{justifyContent:"space-between"}}>Add Agent
                {(auth.role === "company" && name !== "") && <ButtonToolbar onClick={()=>setShowPC(true)} className='col-1'>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="bottom"
                        overlay={popoverHoverFocus}
                    >
                        <div className='btn bg-warning rounded-4 pt-2' style={{width:"auto",aspectRatio:"1/1"}}><BsPersonFillLock size='1.5rem'/></div>
                    </OverlayTrigger>
                </ButtonToolbar>}
            </div>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-4 text-center">
                        <div className=" p-3">
                            <label htmlFor="uploadStaffImg" className="staff-img col-12">
                                <img
                                    onClick={() => setShowImageEdit(true)}
                                    src={srcImg}
                                    //  className={selected != "" ? "w-100" : ""}
                                    alt=""
                                />
                            </label>
                            <div id="uploadStaffImg" hidden className=" form-control" />
                        </div>
                        {
                            auth.role === "company" && userID && (
                                <div
                                    className="btn btn-secondary py-0 px-4 my-2 ms-0"
                                    style={{ fontSize: "14px" }}
                                    onClick={() => setNumberModal(true)}
                                >
                                    Caller Id
                                </div>
                            )
                            // :<></>
                        }
                    </div>
                    <div className="col-md-8">
                        <div className="row my-4">
                            <div className="col-md-7 px-1">
                                <Form.Group className="" controlId="staffName">
                                    <Form.Label className="fw-bolder">Name</Form.Label>
                                    <Form.Control
                                        value={name}
                                        required
                                        onChange={(e) => setName(e.target.value)}
                                        className="py-2"
                                        placeholder="Beserac"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-5 px-1">
                                <Form.Group className="" controlId="staffPost">
                                    <Form.Label className="fw-bolder">Post</Form.Label>
                                    <Form.Control
                                        value={post}
                                        required
                                        onChange={(e) => setPost(e.target.value)}
                                        className="py-2"
                                        placeholder="BDE"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-5 px-1">
                                <Form.Group className="" controlId="staffPhone">
                                    <Form.Label className="fw-bolder">Phone number</Form.Label>
                                    <Form.Control
                                        value={mobile}
                                        required
                                        onChange={(e) => setMobile(e.target.value)}
                                        className="py-2"
                                        placeholder="+91 9876543210"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-7 px-1">
                                <Form.Group className="" controlId="staffPost">
                                    <Form.Label className="fw-bolder">Email</Form.Label>
                                    <Form.Control
                                        value={email}
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="py-2"
                                        placeholder="user@example.com"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row staff-btn-container">
                    <div className="row col-md-6">
                        <div className="col-md-6">
                            <button
                                type="button"
                                onClick={handleClose}
                                className="btn btn-outline-primary staff-btn col-12"
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button
                                type="submit"
                                className={
                                    window.innerWidth > 768
                                        ? "btn btn-primary staff-btn col-12"
                                        : "btn btn-primary staff-btn col-12 mt-2"
                                }
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
            <Modal
                size="lg"
                centered
                show={showImageEdit}
                onHide={closeImagemodel}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <div className="mx-3">
                        <Form onSubmit={handleImageSubmit}>
                            <div className="row my-2">
                                <div className="h4 fw-bolder">Upload Profile Image</div>
                            </div>
                            <div className="row my-2">
                                <div
                                    onDragOver={(e) => handleDragOver(e)}
                                    onDrop={(e) => handleDrop(e)}
                                    className="lead-image-upload text-center p-4"
                                >
                                    {imageData ? (
                                        <>
                                            <Crop
                                                {...{
                                                    result,
                                                    setResult,
                                                    imageData,
                                                    setImageData,
                                                    setSrcImg,
                                                    srcImg,
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <div className="mb-3">
                                                <img className="uploadPreview" src={add_img} alt="" />
                                            </div>
                                            <div className="">
                                                <div>Drag & Drop Files Here</div>
                                                <div>or</div>
                                            </div>
                                        </>
                                    )}
                                    <div className="mt-3">
                                        <div>
                                            <label
                                                htmlFor="leadImage"
                                                className="col-6 btn btn-outline-primary browse-btn"
                                            >
                                                Browse Files
                                            </label>
                                            <input
                                                id="leadImage"
                                                onChange={(e) => handleImage(e)}
                                                hidden
                                                className="form-control"
                                                type="file"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="px-0    ">
                                    <div
                                        onClick={handleClose}
                                        className="btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end"
                                    >
                                        Cancel
                                    </div>
                                    <button
                                        onClick={handleImageSubmit}
                                        className={
                                            window.innerWidth > 768
                                                ? "btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3"
                                                : "btn btn-pill col-md-2 col-12 my-2 btn-primary float-end"
                                        }
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={numberModal}
                onHide={handleNumberClose}
                centered={true}
                size="md"
                contentClassName=""
            >
                <Modal.Body>
                    <ExtensionForm
                        setNumberModal={setNumberModal}
                        userID={userID}
                    />
                </Modal.Body>
            </Modal>
            <PassChange userID={userID} setShowPC={setShowPC} showPC={showPC}/>
        </div>
    );
};

export default StaffAddForm;
