import { Header, Icon, Image } from "semantic-ui-react"
import { ButtonToolbar, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import useAuth from "../../../hooks/useAuth"
import useCompanyServices from "../../../services/companyServices"
import { useState } from "react"
import { MEDIA_URL } from "../../../api/axios"
import ToggleButton from "../components/Toggel_button"
import { Checkbox } from 'semantic-ui-react';
import dummy_profile from "../../../assets/images/imgs/profile_image_dummy.jpg";
import profileImg from "../../../assets/images/imgs/user.png"

const AgentCard = ({ img, user, role, data, open, edit, assignStaff, refresh }) => {
    const { auth } = useAuth()
    const [showListPopover, setShowListPopover] = useState(false)
    const [showImageHover, setShowImageHover] = useState(false)

    const { deleteAgentDetailsToday , putStaffStatusChange } = useCompanyServices()

    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false)

    const handleSubmit = async (e) => {
        try {
            const response = await deleteAgentDetailsToday(auth.role === "company" ? data.id : "")
            if (response?.success) {
                refresh()
                handleClose()
            }
        } catch (err) {

        }
    }

    const popoverHoverFocus = (
        <Popover
            onMouseEnter={()=>setShowListPopover(true)}
            onMouseLeave={()=>setShowListPopover(false)}
            className="agent-list-popover"
        >
            {data?.staff_list?
                <div className="agent-popover-container">
                    {data?.staff_list.map((staff, index)=>{
                        return(
                            <div className={"my-1 agent-popover-card datas"} key={index}>
                                <Header as="h6">
                                    <Image
                                        circular
                                        src={
                                        staff?.image ? MEDIA_URL + staff?.image : dummy_profile
                                        }
                                    />
                                    <Header.Content>
                                        {staff?.first_name}
                                        <Header.Subheader>{staff?.post}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </div>
                        )
                    })}
                </div>
                :
                <div className="agent-popover-card">
                    No Staff Assigned to this Agent
                </div>
            }
        </Popover>
    )
    
    const PopoverHoverFocusImage =()=>{
        return(
        <div
            className={`agent-hover-card ${showImageHover?'show':'hide'}`}
        >
            <div className="row py-2 pe-0">
                <div className="col-md-4 agent-card-img ps-4 pe-0 py-2 text-center">
                    <img 
                        src={img ? MEDIA_URL+img : profileImg}
                        className=""
                        alt=""
                        onMouseEnter={()=>setShowImageHover(true)}
                        onMouseLeave={()=>setShowImageHover(false)}
                    />
                    <div className="col-12 ms-1 me-0 text-center">
                        <div className="agent-hover-name">{user}</div>
                        <div className="agent-hover-role">{role}</div>
                    </div>
                </div>
                <div className="col-md-8 ps-4 py-0 pe-2">
                    <table className="table table-borderless align-items-center mb-1 ps-5 pe-0" >
                        <tbody>
                            <tr>
                                <th className="agent-hover-label">Today's Lead</th>
                                <td className="agent-hover-data">{data.report.total_leads || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Leads Opened</th>
                                <td className="agent-hover-data">{data.report.leads_completed || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Leads Closed</th>
                                <td className="agent-hover-data">{data.report.leads_closed || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Leads Rejected</th>
                                <td className="agent-hover-data">{data.report.leads_rejected || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Total Followups</th>
                                <td className="agent-hover-data">{data.report.total_followUps || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Followups Completed</th>
                                <td className="agent-hover-data">{data.report.follow_ups_completed || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Tasks Pending</th>
                                <td className="agent-hover-data">{data.report.total_not_completed_task || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Tasks Completed</th>
                                <td className="agent-hover-data">{data.report.total_completed_task || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Incoming Calls</th>
                                <td className="agent-hover-data">{data.report.incoming_calls || 0}</td>
                            </tr>
                            <tr>
                                <th className="agent-hover-label">Outgoing Calls</th>
                                <td className="agent-hover-data">{data.report.outgoing_calls || 0}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
    } 

    return (
        <div className="col-lg-4 col-md-6 my-3 ">
            <div className={
                `agent-card ${!data.is_active&&"not_active"}`
            }>
                <PopoverHoverFocusImage />
                {window.innerWidth > 768 ?
                    
                    <div className=" col-12 row py-0">
                        
                        <div 
                        className="col-md-4 agent-card-img my-2 px-4 py-2">
                            <img
                                onMouseEnter={()=>setShowImageHover(true)}
                                onMouseLeave={()=>setShowImageHover(false)}
                                src={img ? MEDIA_URL+img : profileImg}
                                className=""
                                alt=""
                            />
                        </div>
                        <div className="col-md-8 my-2 position-relative px-4">
                            <div className="row text-center mb-1 ps-3 pe-0">
                                <div className="col-md-3">
                                    <div className="agent-card-data">{data.report.total_leads || 0}</div>
                                    <div className="agent-card-label">Lead</div>
                                </div>
                                <div className="col-md-5">
                                    <div className="agent-card-data">{data.report.total_followUps || 0}</div>
                                    <div className="agent-card-label">Followups</div>
                                </div>
                                <div className="col-md-4">
                                    <div className="agent-card-data">{data.report.agent_count||0}</div>
                                    <div className="agent-card-label">Agents</div>
                                </div>
                            </div>
                            <div className="row pb-4 ps-3 pe-0">
                                <div className="agent-card-name col-10">{user}</div>
                                <div className="col-2">
                                    <ButtonToolbar>
                                        <OverlayTrigger
                                            show={showListPopover}
                                            trigger={['hover', 'focus']}
                                            placement="left"
                                            overlay={popoverHoverFocus}
                                        >
                                            <div
                                                className="btn  btn-pill btn-outline-secondary agent-info-btn"
                                                onMouseEnter={() => setShowListPopover(true)}
                                                onMouseLeave={() => setShowListPopover(false)}
                                            >
                                                <span className="agent-info-text">i</span>
                                            </div>
                                        </OverlayTrigger>
                                    </ButtonToolbar>
                                </div>
                                <div className="agent-card-role col-10">{role}</div>
                                <ToggleButton data={data} refresh={refresh}/>
                                {/* <div className="agent-card-role fw-bolder col-12">Agents:{data.report.agent_count||0}</div> */}

                            </div>

                        </div>
                    </div>
                    :
                    <div className=" col-12 row py-0">
                        <div 
                        // onMouseLeave={() => setIsHover(false)} 
                        className="col-md-4 col-4 agent-card-img my-2 px-4 py-1">
                            <img
                            //  onMouseEnter={() => setIsHover(true)}
                              src={img ? MEDIA_URL+img : profileImg} className="" alt="" />
                        </div>
                        <div className="col-8 position-relative ps-4 pe-0 my-auto">

                            <div className="row">
                                <div className="col-9 agent-card-name">{user}</div>
                                <div className="col-3">
                                    <ButtonToolbar>
                                        <OverlayTrigger
                                            // show={showListPopover}
                                            trigger={['click']}
                                            placement="left"
                                            overlay={popoverHoverFocus}
                                        >
                                            <div
                                                className="btn btn-pill btn-outline-secondary agent-info-btn"
                                            >
                                                <span className="agent-info-text">i</span>
                                            </div>
                                        </OverlayTrigger>
                                    </ButtonToolbar>
                                </div>
                                <div className="col-9 agent-card-role">{role}</div>
                                <ToggleButton data={data} refresh={refresh}/>
                                <div className="col-9 agent-card-role fw-bolder">Agents:{data.report.agent_count||0}</div>
                            </div>

                        </div>
                        <div className="row text-center mb-1 mx-auto">
                            <div className="col-4 ">
                                <div className="agent-card-data">{data.report.total_leads || 0}</div>
                                <div className="agent-card-label">Lead</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{data.report.leads_completed || 0}</div>
                                <div className="agent-card-label">Completed</div>
                            </div>
                            <div className="col-4 ">
                                <div className="agent-card-data">{data.report.leads_rejected || 0}</div>
                                <div className="agent-card-label">Rejected</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{data.report.total_followUps || 0}</div>
                                <div className="agent-card-label">Followups</div>
                            </div>
                            <div className="col-4 ">
                                <div className="agent-card-data">{data.report.follow_ups_completed || 0}</div>
                                <div className="agent-card-label">Completed</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{data.report.leads_closed || 0}</div>
                                <div className="agent-card-label">Closed</div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row col-12 ms-1 me-5 ps-2 pe-2 pt-1 pb-3">
                    <div className="col-md-4 px-1 pe-2 pe-md-1 py-2 py-md-0">
                        <button onClick={() => assignStaff(data)} className="col-12 p-2 px-3 btn agent-card-btn-sm btn-primary">Team Assign</button>
                    </div>
                    <div className="col-md-4 px-1 pe-2 pe-md-1 py-2 py-md-0">
                        <button onClick={() => open(data)} value={data.id} className="col-12 btn p-2 px-3 agent-card-btn-sm btn-outline-primary">Assign Lead</button>
                    </div>
                    <div className="col-md-4 px-md-0 px-0 mx-0 py-2 py-md-0">
                        <div className="row m-0 px-0">
                            {window.innerWidth > 768 ?
                                <>
                                    <div className="col-md-6 ps-4">
                                        <button 
                                            onClick={() => edit(data)} 
                                            className="agent-card-btn-xs btn btn-outline-primary"
                                        >
                                            <Icon name="pencil alternate" />
                                        </button>
                                    </div>
                                    <div className="col-md-6 ps-3 px-0">
                                        <button 
                                            onClick={() => setShowModal(true)} 
                                            className="agent-card-btn-xs btn btn-primary"
                                        >
                                            <Icon name="trash alternate outline" />
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-6 ps-1 px-1">
                                        <button onClick={() => edit(data)} className="btn agent-card-btn-sm col-12 px-0 p-2 btn-outline-primary">
                                            <Icon name="pencil alternate" />
                                        </button>
                                    </div>
                                    <div className="col-6 pe-2 px-0">
                                        <button onClick={() => setShowModal(true)} className="btn agent-card-btn-sm col-12 p-2 px-0 btn-primary">
                                            <Icon name="trash alternate outline" />
                                        </button>
                                    </div></>}
                            <Modal
                                show={showModal}
                                onHide={handleClose}
                                centered={true}
                                size="lg"
                                contentClassName="agent-modal"
                            >
                                <Modal.Body className='p-5'>
                                    <div className="">
                                        <div className="row">
                                            <div className="h3 fw-bolder">Delete Staff</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row my-4">
                                                    <div className="col-md-12 px-5 fs-5">
                                                        Are you sure you wan't to delete agent <b className="text-primary fs-4">{user}</b> ?

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row staff-btn-container p-2">
                                            <div className="row col-md-6">
                                                <div className="col-6 p-2">
                                                    <button type='button' onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</button>
                                                </div>
                                                <div className="col-6 p-2">
                                                    <button type='button' onClick={(e) => handleSubmit(e)} className='btn btn-danger staff-btn col-12'>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentCard