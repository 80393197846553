import React, { useReducer } from 'react'
import { useEffect, useState } from 'react'

import "./FollowUps.css"
import {AiOutlineSearch} from 'react-icons/ai'

import FollowUpCard from './components/FollowUpCard'
import useCompanyServices from '../../services/companyServices'
import useLeadServices from '../../services/leadServices'
import useAuth from '../../hooks/useAuth'
import { format } from 'date-fns'
import { Pagination } from '../../components/pagination/Pagination'

import { Dropdown, Icon } from 'semantic-ui-react'
import { DatePicker } from '@mui/x-date-pickers'
import { Button, Modal } from 'react-bootstrap'

const initialState = {
  priority: '',
  date: '',
  from: '',
  to: '',
  dateType: 'Filter',
  priorityType: 'Priority',
  showSearch: false,
  showPicker: false,
  searchQuery: "",
  isSearch: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'priority':
      return { ...state, priority: action.payload };
    case 'date':
      return { ...state, date: action.payload, from: '', to: '' };
    case 'from':
      return { ...state, from: action.payload, date: '' };
    case 'to':
      return { ...state, to: action.payload, date: '' };
    case 'dateType':
      return { ...state, dateType: action.payload };
    case 'priorityType':
      return { ...state, priorityType: action.payload };
    case 'showSearch':
      return { ...state, showSearch: !state.showSearch };
    case 'showPicker':
      return { ...state, showPicker: action.payload };
    case 'searchQuery':
      return { ...state, searchQuery: action.payload }
    case 'isSearch':
      return { ...state, isSearch: action.payload };

    case 'reset':
      return { ...initialState };
  }
}

const FollowUps = () => {
  const [followupList, setFollowUpList] = useState()
  const [fieldTypeList, setFieldTypeList] = useState("")
  const [crntpg, setCrntpg] = useState(1)
  const [limit, setLimit] = useState(0)
  const [item, setItem] = useState(1)

  const [filter, setFilter] = useState("Filter")
  // const [fromDate, setFromDate] = useState("")
  // const [toDate, setToDate] = useState("")

  const [filters, dispatch] = useReducer(reducer, initialState)


  const { auth } = useAuth()

  const { getFollowUpList } = useCompanyServices()
  const { getStaffsFollowUpList, getActiveFieldTypes } = useLeadServices()

  useEffect(() => {
    getFieldTypeList()
  }, [])
  useEffect(() => {

    getFollowUpListData()
  }, [filters.date, filters.priority, crntpg,filters.isSearch])

  const getFieldTypeList = async () => {
    const response = await getActiveFieldTypes()
    if (response !== null) {
      setFieldTypeList(response.data)
    }
  }

  const getFollowUpListData = async (temp) => {

    const data = {
      priority: filters.priority,
      date: filters.date,
      to: filters.to,
      from: filters.from,
      page: crntpg,
      search: filters.isSearch ? filters.searchQuery : ""

    }
    var response = null
    if (auth?.role === 'company') {
      response = await getFollowUpList(data)
    }
    else {
      response = await getStaffsFollowUpList(data)
    }
    if (response !== null) {
      //console.log(response?.data)
      setLimit(response?.data?.total_page)
      setFollowUpList(response.data.leads)
    }
  }

  const handlePriority = (data) => {
    let temp = { priority: data }
    dispatch({ type: 'priority', payload: data })
    // getFollowUpListData(temp)
  }

  const handleFilter = (data) => {
    dispatch({ type: 'dateType', payload: data })
    
    if (data === 'Date' || data === 'Range') {
      dispatch({ type: "showPicker", payload: true })
    }
    setFilter(data)
    if (data == "Today") {
      let temp = { date: format(new Date(), 'yyyy-MM-dd') }
      dispatch({ type: 'date', payload: format(new Date(), 'yyyy-MM-dd') })
      // getFollowUpListData(temp)


    }
    else if (data == "Yesterday") {

      let date = new Date();
      date.setDate(date.getDate() - 1);
      let temp = { date: format(date, 'yyyy-MM-dd') }
      dispatch({ type: 'date', payload: format(date, 'yyyy-MM-dd') })
      // getFollowUpListData(temp)


    }
  }
  const handleDate = async (e) => {
    let temp = { date: format(e['$d'], 'yyyy-MM-dd') }
    dispatch({ type: 'date', payload: format(e['$d'], 'yyyy-MM-dd') })
    handlePickerClose();

    // getFollowUpListData(temp)

  }

  const handleDateRange = async (e) => {
    e.preventDefault()
    // let temp = { from: format(fromDate, 'yyyy-MM-dd'), to: format(toDate, 'yyyy-MM-dd') }
    getFollowUpListData()
    handlePickerClose();


  }


  const handleSearch = () => {
    if (filters.searchQuery != "") {
      dispatch({ type: 'isSearch', payload: true })
    }
    else {
      getFollowUpListData()
    }
  };
  const handlePickerClose = () => {
    dispatch({ type: "showPicker", payload: false })
  };

  return (
    <div className="">
      <div className="row pe-1 align-items-center">
        <div className="col-md-3 col-8 row">
          <div className="col-md  col my-3 ms-md-1 ms-3 pe-0">
            <Dropdown
              className='btn btn-sm btn-primary'
              text="Priority"
            >
              <Dropdown.Menu className='mt-2 p-3'>
                <Dropdown.Item onClick={() => handlePriority("Hot")}>Hot</Dropdown.Item>
                <Dropdown.Item onClick={() => handlePriority("Warm")}>Warm</Dropdown.Item>
                <Dropdown.Item onClick={() => handlePriority("Cold")}>Cold</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col  ms-1 pe-0 ps-0 col-md my-3">
            <Dropdown id="dropdown-basic-button"
              className='btn btn-sm btn-primary'
              text={filters.dateType}>
              <Dropdown.Menu className='mt-2 p-3'>
                <Dropdown.Item onClick={() => handleFilter("Today")}>Today</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Yesterday")}>Yesterday</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Date")}>Specific day</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Range")}>Date Range</Dropdown.Item>
              </Dropdown.Menu></Dropdown>
          </div>
        </div>
        {(filters.priority || filters.status || filters.dateType != "Filter" || filters.isSearch) &&
          <div className="col-2 ms-1 pe-0 ps-0 col-md-1 clear-filter my-3">
            <Button onClick={() => dispatch({ type: 'reset' })} variant="danger" size='sm'>
              {window.innerWidth > 768 ? 'Reset' : <Icon name='x' />}
            </Button>
          </div>}
        <Modal
          show={filters.showPicker}
          onHide={handlePickerClose}
          centered
          size="sm"
          contentClassName="upload-modal"
        >
          <Modal.Body className="p-4">
            {filters.dateType === "Date" &&
              <>
                <div className="col-md-12 my-3 ps-md-0">
                  <DatePicker
                    className='lead-datepicker col-12 px-2'
                    onChange={(e) => handleDate(e)}
                    format="DD-MMM-YYYY"
                  />
                </div>
              </>}
            {filters.dateType === "Range" &&
              <>
                <div className="col-md-12 my-3 ps-md-0">
                  <DatePicker
                    className='lead-datepicker col-12 px-2 '
                    onChange={(e) => dispatch({ type: 'from', payload: format(e['$d'], 'yyyy-MM-dd') })}

                    format="DD-MMM-YYYY"
                  />
                </div>
                <div className="col-md-12 my-3 ps-md-1">
                  <DatePicker
                    className='lead-datepicker col-12 px-2 px-md-0'
                    onChange={(e) => dispatch({ type: 'to', payload: format(e['$d'], 'yyyy-MM-dd') })}
                    format="DD-MMM-YYYY"
                  />
                </div>

                <div className='col-md-12 my-3 ps-md-1'>
                  <button className='btn btn-pill btn-outline-primary col-12 px-2 px-md-0' onClick={(e) => handleDateRange(e)}>search</button>
                </div>


              </>
            }
          </Modal.Body>
        </Modal>
        {/* {filters.dateType === "Date" &&
          <>
            <div className="col-md-2 my-3">
              <DatePicker
                className='lead-datepicker col-12 px-2 px-md-0'
                onChange={(e) => handleDate(e)}
                format="DD-MMM-YYYY"
              />
            </div>
          </>}
        {filters.dateType === "Range" &&
          <>
            <div className="col-md-2 my-3">
              <DatePicker
                className='lead-datepicker col-12 px-2 px-md-0'
                onChange={(e) => dispatch({ type: 'from', payload: format(e['$d']) })}
                format="DD-MMM-YYYY"
              />
            </div>
            <div className="col-md-2 my-3 ps-md-1">
              <DatePicker
                className='lead-datepicker col-12 px-2 px-md-0'
                onChange={(e) => dispatch({ type: 'to', payload: format(e['$d']) })}
                format="DD-MMM-YYYY"
              />
            </div>
            <div className='col-md-1 my-3 ps-md-1'>
              <button className='btn btn-pill btn-outline-primary col-12 px-2 px-md-0' onClick={(e) => handleDateRange(e)}>search</button>
            </div>


          </>
        } */}
        <div id="" className={`${(filters.priority || filters.status || filters.dateType != "Filter") || filters.isSearch ? 'col-md' : 'col-md'} col-12 my-3 me-3 text-md-end align-items-center d-md-flex justify-content-end text-center`}>
          {window.innerWidth > 768 ? <>
            <div class={"ui action input search-bar show"}>
              <input value={filters.searchQuery} onChange={(e) => dispatch({ type: "searchQuery", payload: e.target.value })} type="text" placeholder="Search..." class="rounded-input" />
              <button onClick={handleSearch} class="ui button rounded-button"><AiOutlineSearch/></button>
            </div>

            {/* <div className="col-4 ms-1 me-2 pe-0 ps-0 col-md-1 clear-filter my-3">
              <Button className='search-trigger col-12' onClick={() => dispatch({ type: 'showSearch' })} variant={filters.showSearch ? "danger" : "primary"} size='sm'>
                <Icon name={filters.showSearch ? 'arrow right' : 'search'} size='' />
              </Button>
            </div> */}
          </>
            :
            <div class={"ui action input search-bar show mb-3"}>
              <input value={filters.searchQuery} onChange={(e) => dispatch({ type: "searchQuery", payload: e.target.value })} type="text" placeholder="Search..." class="rounded-input" />
              <button onClick={handleSearch} class="ui button rounded-button"><AiOutlineSearch /></button>
            </div>
          }
        </div>
      </div>
      <div className="row mt-3 mb-3 me-0">
        <div className="col-lg-12">
          {window.innerWidth > 768 && <div className="followup-title-card col-12 row p-3 pe-5">
            <div className="col-1 ps-2 pe-0 p-2 followup-card-head serial-number">Sl.<br />no</div>
            <div className="col-1 p-2 followup-card-head px-0">Name</div>
            <div className="col-2 followup-card-head p-2 pe-0 ps-3">Phone number</div>
            {/* <div className="col-1 followup-card-head p-0"></div> */}
            <div className="col-4 followup-card-head p-2 px-0">E-mail</div>
            <div className="col-1 followup-card-head p-2 ps-0 pe-5">Course</div>
            <div className="col-1 followup-card-head p-2 ps-0 pe-5">Location</div>
            <div className="col-1 followup-card-head p-2 ps-1 pe-0">Priority</div>
            {/* <div className="col-1 followup-card-head p-2 ps-0 pe-0">Follow Up</div> */}
            <div className="col-1 followup-card-head p-2 ps-1 pe-0">Status</div>
            {/* <div className="col-1 followup-card-head p-2 pe-2 text-center">Desc</div> */}
          </div>}
        </div>
      </div>
      <div className="followup-container">
        {followupList && followupList.map(
          (data, index) => {
            return (
              <div key={index} className="row followup-data me-0">
                <FollowUpCard auth={auth} index={(item*(crntpg-1))+index+1} data={data} updateFollowUps={getFollowUpListData} getFieldTypeList={getFieldTypeList} fieldTypeList={fieldTypeList} />
              </div>
            )
          }
        )
        }
      </div>
      {limit > 1 && <Pagination {...{ crntpg, setCrntpg, limit }} />}
    </div>
  )
}

export default FollowUps