import React, { useEffect, useState } from 'react'
import { Checkbox, Dropdown, Icon, Search } from 'semantic-ui-react'
import useAgentServices from '../../../services/agentServices'

const AssignStaffLeadTable = (props) => {

    const [selected, setSelected] = useState('all')
    const { leadList, currentStaff,fkUser, getLeadList, refresh } = props

    const { postStaffAssignLead } = useAgentServices()

    const handleAssignLead = async (lead_id) => {
        try {
            const data = JSON.stringify({
                lead_id: lead_id,
            })
            const response = await postStaffAssignLead(currentStaff, data)
            if (response.success) {
                return true;
            }
        } catch (err) { console.log(err) }
    }

    const options = [
        { key: 1, text: 'All', value: "all" },
        { key: 2, text: 'Assign ', value: "assigned" },
        { key: 3, text: 'Unassign', value: "unassigned" },
    ]
    const handleSearch = () => {

    }

    const handleSelect = (event, data) => {
        setSelected(data.value)
        if (data.value === 'all') {
            getLeadList()
        } else if (data.value === 'assigned'){
            getLeadList("choice", data.value,fkUser)
        } else if (data.value === 'unassigned') {
            getLeadList("choice", data.value)
        }
    }

    return (
        <div className='position-relative'>
            <div className="asign-lead-close">
                <button className='btn' onClick={props.close} ><Icon name="close" size="large" /></button>
            </div>
            <div className="row">
                <div className="h3 fw-bolder col-6">{props.title}</div>
                <div className="col-md-3">
                    {/* <Search
                        input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                        placeholder="Search Here..."
                        // onResultSelect={}
                        size='small'
                        onSearchChange={handleSearch}

                    /> */}
                </div>
                <div className="col-md-2">
                    <Dropdown
                        selection
                        fluid={true}
                        value={selected}
                        options={options}
                        onChange={handleSelect} />
                </div>
            </div>

            <div className="row my-3">
                <div className="assign-table-container p-4">
                    <div>
                        <div className="row py-2 assign-table-header">
                            <div className="col-2">Sl.No</div>
                            <div className="col-3">Name</div>
                            <div className="col-3">Phone Number</div>
                            <div className="col-3">Interested</div>
                            <div className="col-1 ps-0">Assign</div>
                        </div>
                    </div>
                    <div className="my-2 assign-table-body">
                        {leadList && leadList.map((data, index) => <LeadItem handleAssignLead={handleAssignLead} refresh={refresh} currentStaff={currentStaff} index={index} key={index} data={data} selected={selected} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}


const LeadItem = ({ data, index, refresh, currentStaff, handleAssignLead, selected }) => {
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        setChecked(data.is_assigned)
    }, [data])
    const handleAssign = (value) => {
        const assign = handleAssignLead(data?.lead?.id||data?.id)
        if(assign) {
          setChecked(value)  
        }
    }

    const handleCheck = (e) => {
        // setChecked()
        handleAssign(!checked)
        refresh()
    }
    // console.log(data)
    return (
        <div className="row py-2 assign-table-content mb-1">
            <div className="col-2">{index+1}</div>
            <div className="col-3">{data?.name||data.lead.name||""}</div>
            <div className="col-3">{data?.mobile||data?.lead?.mobile||""}</div>
            <div className="col-3">{data?.interest||data?.lead?.intrest||""}</div>
            <div className="col-1 ps-0">
                <Checkbox toggle checked={checked} onChange={handleCheck} disabled={selected==='assigned'||!checked?false:true}  />
            </div>
        </div>
    )
}
export default AssignStaffLeadTable