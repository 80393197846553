import { useEffect } from "react"
import { useState } from "react"
import { Form, Modal, Nav, Tab } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { Popup, Icon } from "semantic-ui-react"
import Swal from "sweetalert2"
import useLeadServices from "../../../services/leadServices"
import CustomerProfile from "../../customer/components/CustomerProfile"
import LeadFieldList from "../../leads/components/LeadFieldList"
import CallLogs from "../../../components/calls/CallLogs"
import FollowUpLog from "./FollowUpLog"
import axios from "axios"
import useCompanyServices from "../../../services/companyServices"
import useBaseServices from "../../../services/baseServices"
import { FiPhoneCall } from "react-icons/fi"
import { CALL_PIN, CALL_UID } from "../../../api/axios"
import { DatePicker } from "@mui/x-date-pickers"
import FollowUpForm from "./FollowUpForm"

const FollowUpCard = ({ index, data, auth, updateFollowUps, getFieldTypeList, fieldTypeList}) => {
    const [descModal, setDescModal] = useState(false)
    const [showProfile, setShowProfile] = useState(false)


    const { putLeadStatusChange, getNextFollowUpCount, getClick2Call, postNewLeadFieldContent, getFollowUpLog } = useLeadServices()
    
    const navigate = useNavigate()

    const fieldData = new FormData()
    //console.log(1)    

    const [id, setId] = useState(data?.id)
    const [name, setName] = useState(data?.name)
    const [mobile, setMobile] = useState(data?.mobile)
    const [mail, setMail] = useState(data?.mail)
    const [course, setCourse] = useState(data?.interest)
    const [location, setLocation] = useState(data?.location)
    const [assign, setAssign] = useState(data?.is_assigned ? "Assigned" : "Unassigned")
    const [source, setSource] = useState(data?.priority)
    const [status, setStatus] = useState(data?.status)
    const [count, setCount] = useState("")

    const [key,setKey] = useState("tab1")

    const loadData = () => {
        setId(data.id)
        setName(data.name)
        setMobile(data.mobile)
        setMail(data.email)
        setCourse(data.interest)
        setLocation(data.location)
        setAssign(data.is_assigned ? "Assigned" : "Unassigned")
        setSource(data.priority)
        setStatus(data.status)
    }
    useEffect(() => {
        loadData()
    }, [data])

    const handleCloseDesc = () => setDescModal(false);

    const handleSubmit = async (key, e) => {
        e && e.preventDefault()
        var datas = null
        // console.log(status)
        if (key === "status") {
            setStatus(e.target.value)
            datas = JSON.stringify({
                'status': e.target.value
            });
        }
        else if (key === "priority") {
            datas = JSON.stringify({
                'priority': e.target.value
            });
        }
        const response = await putLeadStatusChange(id, data = datas)
        if (response?.success) {
            Swal.fire("", "Lead updated", "success")
            updateFollowUps()
        }
        else {
            Swal.fire("", "Lead not updated !!!", "error")
        }
    }

    const getDate = (data) => {
        return new Date(data).toString().slice(4, 15)
    }
    const handleClose = () => setShowProfile(false)

    const handleCall = async()=>{
        try{
            const response = await getClick2Call(data?.mobile)
            if (response?.success){
                // console.log(response?.data)
                let timerInterval
                Swal.fire({
                  html: '<b>Calling <div className="loader"><span className="dot dot-1"></span><span className="dot dot-2"></span><span className="dot dot-3"></span></div> </b>',
                  timer: 2500,
                  timerProgressBar: false,
                  showConfirmButton: false,
                  
                  willClose: () => {
                    clearInterval(timerInterval)
                  }
                })
            }
            else{
              Swal.fire("Error!!!", response?.message, "error")
            }
        }catch(e){
            Swal.fire("", "Something went wrong!!", "error");
        }
      }

    const handleFollowUpModal = async() =>{
        try{
            const response = await getNextFollowUpCount(data?.id)
            if (response?.success){
                setCount(response?.data)
            }
        }
        catch{
        }
        setDescModal(true)
    }

    const handleFieldEdit = async(e) =>{
        e.preventDefault()
        try{
            const response = await postNewLeadFieldContent(data?.id, fieldData)
            // console.log(response?.data)
            if (response?.success){
                Swal.fire("Success", response?.message, "success")
                handleClose()
            }
            else{
                Swal.fire("", response?.message, "error")
            }
        }catch{}
        // console.log(datas, leadId)
    }

    return (
        <div className="col-lg-12">
            {/* <Modal
                size="lg"
                centered
                show={descModal}
                onHide={handleCloseDesc}
                contentClassName="followup-desc-modal px-5"
            >
                <Modal.Body>
                    <FollowUpForm  id={data?.id} count={count} handleCloseDesc={handleCloseDesc} />
                </Modal.Body>
            </Modal> */}
            {window.innerWidth > 768 ?
                <div className="followup-card col-12 row p-3 pe-5 ">
                    <div className="col-1 ps-3 pe-0 p-1 my-2 followup-card-title">{index}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden mouse-pointer text-primary" onClick={(e) => setShowProfile(true)}>{name}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 pe-0 ps-3 ">{mobile}</div>
                    <div className="col-1 my-1 followup-card-title followup-data p-1 pe-0 ps-5 ">
                        <FiPhoneCall size="28px" className="btn call-onclick" onClick={handleCall} />
                    </div>
                    <div className="col-4 my-2 followup-card-title followup-data p-1 ps-0 pe-0 overflow-hidden">{mail}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 ps-0 pe-5 overflow-hidden">{course}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden">{location}</div>
                    <div className="col-1 my-2 followup-card-title pe-0 ps-1">
                        <div>
                            <Form.Select
                                value={source}
                                onChange={(e) => (handleSubmit("priority", e))}
                                className="priority-dropdown"
                                size="sm"
                            >
                                <option className="menu" value="Hot">Hot</option>
                                <option className="menu" value="Warm">Warm</option>
                                <option className="menu" value="Cold">Cold</option>
                            </Form.Select>
                        </div>
                    </div>
                    {/* <div className="col-1 my-2 followup-card-title pe-0 ps-0">
                        <span className="col-12 btn btn-sm btn-warning follow-btn" onClick={handleFollowUpModal}>Follow Up</span>
                    </div> */}
                    <div className="col-1 my-2 followup-card-title pe-0 ps-1">
                        <div className="">
                            <Form.Select
                                value={status}
                                onChange={(e) => (handleSubmit("status", e))}
                                className="status-dropdown"
                                size="sm"
                            >
                                <option className="menu" value="New">New</option>
                                <option className="menu" value="Open">Open</option>
                                <option className="menu" value="Closed">Closed</option>
                                <option className="menu" value="Paused">Paused</option>
                                <option className="menu" value="Rejected">Rejected</option>
                                <option className="menu" value="Unavailable">Unavailable</option>
                            </Form.Select>
                        </div>
                    </div>
                </div>
                :
                <div className="followup-card responsive col-12 p-3 pe-0 my-2">
                    <table className=" my-2 followup-card-title followup-data p-1 pe-5 ps-3 ">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>
                                    <div className=" overflow-auto mouse-pointer text-primary" onClick={(e) => setShowProfile(true)}>{data?.name}</div>
                                </td>
                            </tr>
                            <tr>
                                <th>Mobile</th>
                                <td className="d-flex align-items-center">
                                    <div className="col-10 my-2">{mobile}</div>
                                    <div className="col-2">
                                        <FiPhoneCall size="28px" className="btn call-onclick" onClick={handleCall} />
                                    </div>
                                    
                                </td>
                            </tr>
                            <tr>
                                <th>Mail</th>
                                <td>{mail}</td>
                            </tr>
                            <tr>
                                <th>Course</th>
                                <td>{course}</td>
                            </tr>
                            <tr>
                                <th>Location</th>
                                <td>{location}</td>
                            </tr>
                            <tr>
                                <th>Priority</th>
                                <td>
                                    <div>
                                        <Form.Select
                                            value={source}
                                            onChange={(e) => (handleSubmit("priority", e))}
                                            className="priority-dropdown"
                                            size="sm"
                                        >
                                            <option className="menu" value="Hot">Hot</option>
                                            <option className="menu" value="Warm">Warm</option>
                                            <option className="menu" value="Cold">Cold</option>
                                        </Form.Select>
                                    </div>
                                </td>
                            </tr>
                            {/* <tr>
                                <th>Follow Up</th>
                                <td>
                                    <span className="col-6 btn btn-sm btn-warning follow-btn" onClick={handleFollowUpModal}>Follow Up</span>
                                </td>
                            </tr> */}
                            <tr>
                                <th>Status</th>
                                <td>
                                    <div className="">
                                        <Form.Select
                                            value={status}
                                            onChange={(e) => (handleSubmit("status", e))}
                                            className="status-dropdown"
                                            size="sm"
                                        >
                                            <option className="menu" value="New">New</option>
                                            <option className="menu" value="Open">Open</option>
                                            <option className="menu" value="Closed">Closed</option>
                                            <option className="menu" value="Paused">Paused</option>
                                            <option className="menu" value="Rejected">Rejected</option>
                                            <option className="menu" value="Unavailable">Unavailable</option>
                                        </Form.Select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            <Modal
                show={showProfile}
                onHide={handleClose}
                centered={true}
                size="xl"
                contentClassName="lead-modal px-md-4 py-2 p-4"
            >
                <Modal.Body className=''>
                    <div className='card document-card row'>
                        <CustomerProfile data={data} refresh={updateFollowUps} handleProfileClose={handleClose} type={"Lead"} />
                        <div className='col-12'>
                            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>

                                <div className="row">
                                    <div className="col-12">
                                        <Nav variant="pills" className="flex-md-row flex-column">
                                            ` <Nav.Item className='pt-2 px-md-3'>
                                                <Nav.Link className='py-3 px-md-4 nav-panes' eventKey="tab1">Details</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='pt-2'>
                                                <Nav.Link className='py-3 nav-panes' eventKey="tab2">Follow Ups</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='pt-2 px-md-3'>
                                                <Nav.Link className='py-3 px-md-3 nav-panes' eventKey="tab3">Call Logs</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <div className="col-12 pt-3 py-0">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tab1" className=" pt-0">
                                                <div className="col-12 text-end px-4 pt-0 pb-3">
                                                    <div onClick={(e)=>handleFieldEdit(e)} className="btn btn-success col-md-2 col-12">Update</div>
                                                </div>
                                                <div className='px-2 field-card'>
                                                        <div className='row'>
                                                            {fieldTypeList && fieldTypeList.map(
                                                                (field, index) => {
                                                                    return (
                                                                        <LeadFieldList key={index} field={field} id={data.id} refresh={getFieldTypeList} datas={fieldData}/>
                                                                    )
                                                                }
                                                            )}
                                                        </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab2">
                                                
                                                <FollowUpLog handleFollowUpModal={handleFollowUpModal} count={count} handleCloseDesc={handleCloseDesc} descModal={descModal}  id={data.id} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab3">
                                                <CallLogs type={'lead'} content={data} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>

                            </Tab.Container>
                        </div>
                        <div className='col-12 row py-3'>
                            <div className='col-7 col-md-9'></div>
                            <div onClick={handleClose} className='btn btn-primary btn-pill col-4 col-md-2'>Close</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default FollowUpCard