import { DatePicker } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import React from 'react'
import { Form } from 'react-bootstrap'

function NewFieldForms({data,datas}) {
    const handleData = (e) =>{
        // e.preventDefault()
        if (data.type==="File"||data.type==="Image"){
            datas.delete(data?.name)
            datas.append(data?.name, e.target.files[0])
        }
        else if(data.type==="DateField"){
            datas.delete(data?.name)
            datas.append(data?.name, format(e['$d'], 'yyyy-MM-dd HH:MM:SS'))
        }
        else{
            datas.delete(data?.name)
            datas.append(data?.name, e.target.value)
        }
    }
  return (
    <Form.Group className='' controlId='leadName'>
        <Form.Label className='fw-bolder'>{data?.name}</Form.Label>
        {data.type==="Character"&&
        <Form.Control
            type="text"
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="Number"&&
        <Form.Control
            type="number"
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="Email"&&
        <Form.Control
            type="email"
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="TextArea"&&
        <Form.Control
            as='textarea'
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="DateField"&&
        <DatePicker
            label={data?.name}
            className="reminder-datepicker form-control"
            onChange={(e)=>handleData(e)}
            format="DD / MMM / YYYY"
        />}
        {data.type==="File"&&
        <Form.Control
            type="file"
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="Image"&&
        <Form.Control
            type="file"
            className='py-2'
            accept='image/*'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="Choice"&&
        <Form.Select
        className="status-dropdown pe-4 field mouse-pointer"
        size="md"
        // value={dataContentList}
        onChange={(e)=>handleData(e)}
        >
            {data?.choices&&data.choices.map((data,i)=>{return(<option key={i} value={data.text}>{data.text}</option>)})}
        </Form.Select>    
    }
    </Form.Group>
  )
}

export default NewFieldForms
