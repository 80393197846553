import { FiDownload } from "react-icons/fi"
import ReactPlayer from "react-player"
import { REC_URL } from "../../../api/axios"

const LogItem = ({ index, data }) => {

    const getDate = (data) => {
        return new Date(data).toString().slice(4, 15)
    }
    const getTime = (data) => {
        return new Date(data).toLocaleTimeString()
    }
    const getDuration = (data) => {
        if (data > 3600) {
            let hours = Math.floor(data / 3600)
            let min = Math.floor((data % 3600) / 60)
            let sec = ((data % 3600) % 60)
            let time = hours + "h " + min + "m " + sec + "s"
            return time;
        }
        else if (data > 60) {
            let min = Math.floor(data / 60)
            let sec = data % 60
            let time = min + "m " + sec + "s"
            return time;
        }
        else {
            let time = data + "s"
            return time;
        }
    }
    const getStatus = (data) => {
        if (data == "CANCEL"|| data == "Cancel"|| data == "NOANSWER")  {
            return "NO RESPONSE"
        }
        return data
    }

    return (
        <div className="col-lg-12">
            {window.innerWidth > 768 ?
                <div className="followup-card col-12 row p-3 pe-0 ">
                    <div className="ps-3 pe-0 p-1 my-2 followup-card-title call">{index}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden mouse-pointer" >{data?.created_at&&getDate(data?.created_at)}</div>
                    <div className="col-1 col-2 overflow-scroll my-2 followup-card-title followup-data p-1 pe-5 ps-0 ">{data.name}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden">{data.client_number}</div>
                    <div className="col-1 col-2 overflow-scroll my-2 followup-card-title followup-data p-1 pe-5 ps-0 ">{data.agent_name}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden">{getTime(data.start_time)}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden">{getTime(data.end_time)}</div>
                    <div className=" my-2 followup-card-title call followup-data p-1 px-2 overflow-hidden">{getDuration(data?.call_duration)}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 ps-0 pe-1 overflow-hidden">{getStatus(data?.call_status)}</div>
                    {data?.call_duration?
                        <div className="col-3 followup-card-title followup-data p-0 pe-2 overflow-hidden d-flex">
                            <ReactPlayer
                                url={REC_URL + data?.recording_url}
                                // minWidth="14rem"
                                height="2.5rem"
                                playing={false}
                                controls={true}
                                config={
                                    {
                                    file: {
                                        attributes: {
                                        controlsList: 'nodownload' 
                                        } 
                                    } 
                                    }
                                }
                                />
                                &nbsp;
                            <a
                                href={REC_URL + data?.recording_url}
                                className="btn btn-success call"
                                >
                                    <span>
                                        <FiDownload size="18px" />
                                    </span>
                            </a>
                        </div>:
                        <div className="col-3"></div>
                    }
                </div>
                :
                <div className="followup-card responsive col-12 p-3 pe-0 my-2">
                    <table className=" my-2 followup-card-title log-data p-1 pe-5 ps-3 ">
                        <tbody>
                            <tr>
                                <th>Date</th>
                                <td>{data?.created_at.slice(0, 10)}</td>
                            </tr>
                            <tr>
                                <th>Client Name</th>
                                <td>{data?.name}</td>
                            </tr>
                            <tr>
                                <th>Mobile</th>
                                <td>{data?.client_number}</td>
                            </tr>
                            <tr>
                                <th>Agent Name</th>
                                <td>{data?.agent_name}</td>
                            </tr>
                            <tr>
                                <th>Start Time</th>
                                <td>{getTime(data?.start_time)}</td>
                            </tr>
                            <tr>
                                <th>End Time</th>
                                <td>{getTime(data?.end_time)}</td>
                            </tr>
                            <tr>
                                <th>Duration</th>
                                <td>{getDuration(data?.call_duration)}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>{getStatus(data?.call_status)}</td>
                            </tr>
                            {data?.call_duration&&
                                <tr>                    
                                <td className="" colSpan={2}>
                                    <div className="d-flex">
                                    <ReactPlayer
                                        url={REC_URL + data?.recording_url}
                                        width="20rem"
                                        height="2.5rem"
                                        playing={false}
                                        controls={true}
                                        config={
                                        {
                                            file: {
                                            attributes: {
                                                controlsList: 'nodownload' 
                                            } 
                                            } 
                                        }
                                        }
                                    />
                                    &nbsp;
                                    <a
                                        href={REC_URL + data?.recording_url}
                                        className="btn btn-success"
                                    >
                                        <FiDownload size="15px" />
                                    </a>
                                    </div>
                                </td>
                                </tr>||<></>
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div >
    )
}

export default LogItem