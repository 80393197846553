import axios from "axios";
import { CALL_PIN, CALL_UID } from "../api/axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useLeadServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get lead list
    const getLeadBulkUpload = async () => {
        const response = await axiosPrivate.get("/lead/bulkupload/")
        return response.data
    }

    //post bulk lead file
    const postBulkUploadToAgent = async(data) => {
        const response = await axiosPrivate.post("/lead/bulkupload/agent/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //post bulk lead file
    const postBulkUploadToMultipleAgents = async(data) => {
        const response = await axiosPrivate.post("/lead/bulkupload/multiple_agents/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //get bulk lead file
    const getBulkUploadToMultipleAgents = async(data) => {
        const response = await axiosPrivate.get("/lead/bulkupload/multiple_agents/")
        return response.data
    }

    //post bulk lead file
    const postLeadBulkUpload = async(data) => {
        const response = await axiosPrivate.post("/lead/bulkupload/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //change lead status and details
    const putLeadStatusChange = async (id, data) => {
        const response = await axiosPrivate.put("/lead/lead_status_change/" + id + "/", data)
        return response.data
    }

    //get leads assigned to agent in company page,
    //get leads assigned to staff in agent page
    const getAdminAssignedLeadList = async (id) => {
        var response
        if (id) {
             response = await axiosPrivate.get("/lead/assigned_lead_list/" + id + "/")
             const list = response.data.data.map((data)=>data.lead)
            //  console.log(list)
             response.data.data=list
        } else {
             response = await axiosPrivate.get("/lead/assigned_lead_list/")
        }
        return response.data
    }

    //get followup list for agent or staff
    const getStaffsFollowUpList = async (data) => {
        const response = await axiosPrivate.get("/lead/follow_up_list/", {
            params: { ...data }
        })
        return response.data
    }

    //get all field types 
    const getAllFieldTypes = async() => {
        const response = await axiosPrivate.get("/lead/field_type/create/")
        return response.data
    }

    //get all active field types 
    const getActiveFieldTypes = async() => {
        const response = await axiosPrivate.get("/lead/field_type/active/")
        return response.data
    }

    //get all excel field types 
    const getExcelFieldTypes = async() => {
        const response = await axiosPrivate.get("/lead/field_type/excel/")
        return response.data
    }

    //create field types
    const postCreateFieldType = async(data) => {
        const response = await axiosPrivate.post("/lead/field_type/create/", data)
        return response.data
    }

    //create choices for field types
    const postCreateChoice = async(id, data) => {
        const response = await axiosPrivate.post(`/lead/choice/add/${id}/`, data)
        return response.data
    }

    //get choices of  field types
    const getFieldChoices = async(id) => {
        const response = await axiosPrivate.get(`/lead/choice/add/${id}/`)
        return response.data
    }

    //get field types details
    const getFieldTypeDetails = async(id) => {
        const response = await axiosPrivate.get("/lead/field_type/edit/"+id+"/")
        return response.data
    }

    //edit field types details
    const putFieldTypeDetails = async(id, data) => {
        const response = await axiosPrivate.put("/lead/field_type/edit/"+id+"/", data)
        return response.data
    }

    //delete field types
    const deleteFieldType = async(id) => {
        const response = await axiosPrivate.delete("/lead/field_type/edit/"+id+"/")
        return response.data
    }

    //change field types status
    const putFieldTypeStatus = async(id, data) => {
        const response = await axiosPrivate.put("/lead/field_type/status/"+id+"/", data)
        return response.data
    }

    //get all lead field Contents
    const getAllFieldContents = async(id, name) => {
        const response = await axiosPrivate.get("/lead/field_data/create/"+id+"/", {
            params: { "name": name }
        })
        return response.data
    }

    //get bulk of field datas
    const getBulkFieldContent = async(id) => {
        const response = await axiosPrivate.get("/lead/field_data/bulk_load/"+id+"/")
        return response.data
    }

    //add field content with lead id
    const postFieldContent = async(id, data) => {
        const response = await axiosPrivate.post("/lead/field_data/create/"+id+"/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //add field data of new lead with lead id
    const postNewLeadFieldContent = async(id, data) => {
        const response = await axiosPrivate.post("/lead/field_data/new_lead/"+id+"/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //add field data of new customer with customer id
    const postNewCustomerFieldContent = async(id, data) => {
        const response = await axiosPrivate.post("/lead/field_data/new_customer/"+id+"/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //get field data
    const getFieldContent = async(id) => {
        const response = await axiosPrivate.get("/lead/field_data/edit/"+id+"/")
        return response.data
    }

    //edit field data field id
    const putFieldContent = async(id, data) => {
        const response = await axiosPrivate.put("/lead/field_data/edit/"+id+"/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //delete field
    const deleteFieldContent = async(id) => {
        const response = await axiosPrivate.delete("/lead/field_data/edit/"+id+"/")
        return response.data
    }

    //get click to call api response
    const getClick2Call = async(destination) => {
        const response = await axiosPrivate.get("/lead/call/click2call/", {
            params: {
                'uid':CALL_UID,
                'pin':CALL_PIN,
                'destination':destination
            }
        })
        return response.data
    }

    //get all call logs
    const getCallLogs = async(data) => {
        // const response = await axios.get("https://lead-management-api.foxa.in/api/v1/lead/call/logs/", {
        const response = await axiosPrivate.get("/lead/call/logs/", {
            params: { ...data }
        })
        return response.data
    }

    //get next follow_up count (lead id)
    const getNextFollowUpCount = async(id) => {
        const response = await axiosPrivate.get("/lead/follow_up_count/"+id+"/")
        return response.data
    }

    //get lead follow_up log (lead id)
    const getFollowUpLog = async(id) => {
        const response = await axiosPrivate.get("/lead/follow_up_add/"+id+"/")
        return response.data
    }

    //post lead new follow_ups (lead id)
    const postNewFollowUp = async(id, data) => {
        const response = await axiosPrivate.post("/lead/follow_up_add/"+id+"/", data)
        return response.data
    }

    //post lead new follow_up reminder
    const postNewFollowUpReminder = async(data) => {
        const response = await axiosPrivate.post("/lead/follow_up_reminder/", data)
        return response.data
    }

    //get lead new follow_up reminder
    const getNewFollowUpReminder = async() => {
        const response = await axiosPrivate.get("/lead/follow_up_reminder/")
        return response.data
    }

    //edit lead follow_up reminder
    const putFollowUpReminder = async(id, data) => {
        const response = await axiosPrivate.put("/lead/follow_up_reminder/edit/"+id+"/", data)
        return response.data
    }    

    //get all sources 
    const getAllSource = async() => {
        const response = await axiosPrivate.get("/lead/source/create/")
        return response.data
    }

    //get all active sources
    const getActiveSource = async() => {
        const response = await axiosPrivate.get("/lead/source/active/")
        return response.data
    }


    //create sources
    const postCreateSource = async(data) => {
        const response = await axiosPrivate.post("/lead/source/create/", data)
        return response.data
    }

    //get field types details
    const getSourceDetails = async(id) => {
        const response = await axiosPrivate.get("/lead/source/edit/"+id+"/")
        return response.data
    }

    //edit field types details
    const putSourceDetails = async(id, data) => {
        const response = await axiosPrivate.put("/lead/source/edit/"+id+"/", data)
        return response.data
    }

    //delete field types
    const deleteSource = async(id) => {
        const response = await axiosPrivate.delete("/lead/source/edit/"+id+"/")
        return response.data
    }


    //returns
    return {
        //get services
        getLeadBulkUpload,
        getBulkUploadToMultipleAgents,
        getAdminAssignedLeadList,
        getStaffsFollowUpList,
        getAllFieldTypes,
        getActiveFieldTypes,
        getExcelFieldTypes,
        getFieldTypeDetails,
        getAllFieldContents,
        getBulkFieldContent,
        getFieldContent,
        getClick2Call,
        getCallLogs,
        getNextFollowUpCount,
        getFollowUpLog,
        getNewFollowUpReminder,
        getAllSource,
        getActiveSource,
        getSourceDetails,
        getFieldChoices,

        //post services
        postLeadBulkUpload,
        postBulkUploadToAgent,
        postBulkUploadToMultipleAgents,
        postCreateFieldType,
        postFieldContent,
        postNewLeadFieldContent,
        postNewCustomerFieldContent,
        postNewFollowUp,
        postNewFollowUpReminder,
        postCreateSource,
        postCreateChoice,

        //put services
        putLeadStatusChange,
        putFieldTypeDetails,
        putFieldTypeStatus,
        putFieldContent,
        putFollowUpReminder,
        putSourceDetails,

        //delete services
        deleteFieldType,
        deleteFieldContent,
        deleteSource,
        
    }
}
export default useLeadServices