import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa'
import { BiDownArrow } from 'react-icons/bi'
import Swal from 'sweetalert2';
import useLeadServices from '../../../services/leadServices';
import TableData from './TableData';
import { Dropdown } from 'semantic-ui-react'



const FieldSettings = () => {
  const [createModal, setCreateModal] = useState(false)
  const [fieldName, setFieldName] = useState("")
  const [fieldType, setFieldType] = useState("Character")
  const [fieldId, setFieldId] = useState("")
  const [fieldTypeList, setFieldTypeList] = useState("")
  const [showChoice, setShowChoice] = useState(false)
  const [Choice, setChoice] = useState([])
  const [ChoiceName, setChoiceName] = useState()
  const [choiceAdd, setChoiceAdd] = useState(0)

  const { postCreateFieldType, getAllFieldTypes ,
    postCreateChoice, deleteFieldType} = useLeadServices()

  useEffect(() => {
    getFieldTypeList()
  }, [])

  const handleClose = () =>{
    console.log(choiceAdd)
    if(fieldType==="Choice" && choiceAdd<2){
      handleFieldDelete()
    }
    setShowChoice(false)
    setFieldName('')
    setChoice([])
    setChoiceAdd(0)
    setFieldType('Character')
    getFieldTypeList()
    setCreateModal(false)
  }

  const getFieldTypeList = async () => {
    let response = await getAllFieldTypes()
    if (response.success) {
      setFieldTypeList(response.data)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {
      if (fieldType) {
        const data = JSON.stringify({
          "name":fieldName,
          "type":fieldType
        })
        
        let response = await postCreateFieldType(data)
        if (response.success) {
          if(fieldType === "Choice"){
            console.log(showChoice)
            setFieldId(response?.data?.id)
            setShowChoice(true)
          }else{
            handleClose()
            Swal.fire("","Field created succesfully", "success")
          }
        } else {
          Swal.fire("", response?.message, "error")

        }
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
      // console.log("error")
    }

  }

  const handleFieldDelete = async () =>{
    try{
      if(fieldId)
      await deleteFieldType(fieldId)
      getFieldTypeList()
      Swal.fire("","Dropdown Field creation canceld due to isufficient options","info")
    }catch(err){
      Swal.fire("", "Something went wrong!!", "error");
      // console.log(err)
    }
  }

  const addChoice = async (x) =>{
    try{
      let data;
      data = JSON.stringify({
        text:ChoiceName,
        fk_field:fieldId
      })
    // }
      let res = await postCreateChoice(fieldId, data)
      console.log(res)
      if(!res?.success){
        Swal.fire("", res?.message || "failed to add choice!!","error")
      }
      else{
        setChoice(res?.data)
        setChoiceAdd((x)=>x+1)
      }
    }catch(err){
      console.log(err)
    }
  } 



  return (
    <>
      <div className="row position-relative mb-5 ms-4">
        <button onClick={()=>{setCreateModal(true)}} className='btn col-12 col-md-2 position-absolute me-3 btn-pill end-0 btn-success pill '>
          <FaPlus size="14px" /> Add new
        </button>
        <Modal

          show={createModal}
          onHide={()=>{setCreateModal(false)}}
          centered={true}
          size="md"
          contentClassName=""
        >
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <div className='p-2'>
                <h3>Create Field Type</h3>
                <div className="row my-3">
                    <div className="col-md-7 px-1 col-12">
                        <Form.Group className='' controlId='staffName'>
                            <Form.Label className=''>Name</Form.Label>
                            <Form.Control value={fieldName} onChange={(e) => setFieldName(e.target.value)} className='py-2' placeholder="Eg: Gender" />
                        </Form.Group>
                    </div>
                    <div className="col-md-5 px-1 col-12">
                        <Form.Group className='' controlId=''>
                            <Form.Label className=''>Type</Form.Label>
                            <Form.Select
                              className={"mouse-pointer type-dropdown "
                              // + window.innerWidth<768&&"mobile-view-dropdown "
                            }
                              size="md"
                              value={fieldType}
                              onChange={(e)=>setFieldType(e.target.value)}
                              >
                              <option className="menu" value="Character">Character</option>
                              <option className="menu" value="Number">Number</option>
                              <option className="menu" value="Email">Email</option>
                              <option className="menu" value="TextArea">TextArea</option>
                              <option className="menu" value="File">File</option>
                              <option className="menu" value="Image">Image</option>
                              <option className="menu" value="DateField">Date</option>
                              <option className="menu" value="Choice">Dropdown</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='col-12 px-2'>
                      <Form.Text className='text-muted'><b>Note : </b>For phone numbers please select Character type</Form.Text>
                    </div>
                </div>
                <div className="position-relative mb-5">
                  <div className='position-absolute end-0 row'>
                    <div onClick={()=>{setCreateModal(false)}} className='col btn btn-danger mx-2'>Cancel</div>
                    <button onClick={(e)=>handleSubmit(e)} className='col btn btn-primary'>Submit</button>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="row my-3">
        <div className="settings-table-container">
          <div>
            {window.innerWidth>768&&
              <div className="row p-2 pe-4 settings-table-header">
                <div className="col-1 ">Sl.No</div>
                <div className="col-5 ">Field Name</div>
                <div className="col-3 ">Field Type</div>
                <div className="col-1 ps-3">Edit</div>
                <div className="col-1 ps-3">Active</div>
                <div className="col-1 ps-4">Delete</div>
              </div>
            }
          </div>
          <div className="my-2 settings-table-body">
            {fieldTypeList && fieldTypeList.map((data, index) =>
              <TableData data={data} refresh={getFieldTypeList} type={"Field Type"} index={index} key={index}/>
            )}

          </div>
        </div>
      </div>
      <Modal
      show={showChoice}
      onClose={handleClose}
      centered={true}
      size='md'>
        <Modal.Body>
          <div className='col-12 px-5 py-3 text-center'>
            <div className='h4 text-secondary'>Add Choice</div>
        <div className='Dropdown row col-12 mt-2 pt-3'>
          {fieldName||"Marital Status"} : 
          {/* <div className='col-9 ms-2 text-secondary row  rounded-1 pb-1 d-flex '>
          <div className='row d-flex choiceView text-dark pe-0 rounded-2 py-2'>{Choice[0]?.text||"Choices shows here"} <BiDownArrow className='col-2 p-0 mt-1'/></div>
            {Choice?.length>0&&Choice.map((data,i)=>{
            return(i!==0&&<div key={i} className='row d-flex choiceView text-dark pe-0 rounded-2 py-2 mt-1 border'>{data.text}</div>)})}
          </div> */}
          <Dropdown className='col-8 field' text='Choices' options={Choice.length>0?Choice:[{text:"Choices"}]} open={true} />

           <div className='row mt-3 col-12'>
            <div className='col-12 text-start pb-1 row'>Input fields :</div>
            <input value={ChoiceName} onChange={(e) => setChoiceName(e.target.value)} className=' col-10 ChoiceInpt' placeholder="Eg: Gender"/>
            <div onClick={()=>addChoice(ChoiceName)} className='fa-icon btn ms-3 bg-success col-3'>
            <FaPlus size="14px" className='text-light' />
            </div>
          </div>
          <div>
            <div className='passC'>
              <div onClick={handleClose} className='passC bg-secondary btn mt-4 '>Close</div>
            </div> 
          </div>
          </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FieldSettings