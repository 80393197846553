import './Staff.css'
import { Icon } from 'semantic-ui-react'
import StaffCard from './components/StaffCard'
import { Modal } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import StaffAddForm from './components/StaffAddForm'
import add_img from "../../assets/images/icons/add_img.png"
import useAuth from '../../hooks/useAuth'
import { Navigate } from 'react-router-dom'
import useBaseServices from '../../services/baseServices'
import useCompanyServices from '../../services/companyServices'
import useAgentServices from '../../services/agentServices'
import AssignStaffLeadTable from './components/AssignStaffLeadTable'
import { MEDIA_URL } from '../../api/axios'
// import axios from 'axios'


const Staff = () => {
    const [addModal, setAddModal] = useState(false)
    const [assignModal, setAssignModal] = useState(false)

    const [name, setName] = useState("")
    const [post, setPost] = useState("")
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")

    const [currentStaff, setCurrentStaff] = useState("")
    const [fkUser, setFkUser] = useState("")
    const [srcImg, setSrcImg] = useState("")
    const [result, setResult] = useState("")
    const [imageName, setImageName] = useState("")
    const [userID, setUserID] = useState("")

    //lists
    const [staffList, setStaffList] = useState("")
    const [leadList, setLeadList] = useState("")

    const [imagePreview, setImagePreview] = useState(add_img)
    const [imageData, setImageData] = useState("")

    const [edit, setEdit] = useState()

    const { auth } = useAuth()

    //services
    const { postStaffRegister } = useBaseServices()
    const { getCompanyStaffList, putCompanyStaffDetails } = useCompanyServices()
    const { getAgentStaffList, getStaffAssignLead } = useAgentServices()

    useEffect(() => {
    if(srcImg === ""){
        setSrcImg(add_img)
    }
        getStaffList()
    }, [srcImg])

    useEffect(()=>{
        getStaffList()
    },[assignModal])



    const getStaffList = async (e) => {
        try {
            var response;
            if (auth.role === "company") {
                response = await getCompanyStaffList()
            }
            else if (auth.role === "agent") {
                response = await getAgentStaffList()
            }
            if (response?.success) {
                // console.log(response?.data)
                setStaffList(response?.data)
            }
        } catch (err) { console.log(err) }

    }

    const getLeadList = async (id, choice) => {
        try {
            const response = await getStaffAssignLead(currentStaff||id, choice)
            if (response.success) {
              // console.log(response?.data)
              setLeadList(response.data)
            }
          } catch (err) { console.log(err) }
    }

    const handleOpenAssignModal = (data) => {
        setAssignModal(true)
        setCurrentStaff( data.id)
        //console.log(data)
        setFkUser( data.fkUser)
        getLeadList( data.id)
    }
    const handleImage = (e) => {
        // console.log(e.target.files[0])
        if (!e.target.files || e.target.files.length === 0) {
            setImageData(undefined)
            return
        }
        setSrcImg(URL.createObjectURL(e.target.files[0]))
        setImageName(e.target.files[0].name)
        setImageData(e.target.files[0])
        setResult(e.target.files[0])
        // setImageData(e.target.files[0])
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log(name)
        // console.log(result)
        try {
            const data = new FormData();
            if (edit) {
                data.append("first_name", name)

            } else {
                data.append("name", name)
            }
            if (imageData) {
                data.append("image", result)
            }
            data.append("mobile", mobile)
            data.append("email", email)
            data.append("post", post)

            var response;
            if (edit) {
                response = await putCompanyStaffDetails(currentStaff, data)
            }
            else {
                response = await postStaffRegister(data)
            }
            if (response.success) {
                setAddModal(false);
                handleClear()
                getStaffList()
            }

        } catch (err) {

        }
    }

    // const handleUrltoImg =async (x) =>{
    //     console.log(x)
    //     try{
    //         // const url = "https://plus.unsplash.com/premium_photo-1676573884291-bac996cdf740?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
    //     const response = await fetch(x);
    //     console.log(response)
    //     const blob = await response.blob();
    //     const imageUrl = URL.createObjectURL(blob);
    //     setImageSrc(imageUrl);
    //     console.log(imageSrc)
    //   } catch (error) {
    //     console.log('Error:', error);
    //   }

        // console.log(x)
        // await fetch(x)
        // .then((res) => res.blob())
        // .then((blob) => {
        //     const file = new File([blob], "filename.jpeg");
        //     setImageData(file);
        //     setImageSrc(URL.createObjectURL(file))
        //     setSrcImg(URL.createObjectURL(file))
        // });
    // }
    // console.log(imageData)
    
    const handleEdit = (data) => {
        setName(data.first_name)
        setEmail(data.email)
        setMobile(data.mobile)
        setPost(data.post)
        setSrcImg(MEDIA_URL + data.image)
        setImagePreview(MEDIA_URL + data.image)
        setEdit(true)
        setCurrentStaff(data.id)
        setUserID(data.fkUser)
        handleOpenAddModal("",true)
        // handleUrltoImg(MEDIA_URL + data.image)
    }

    const handleClear = () => {
        setName("")
        setEmail("")
        setMobile("")
        setPost("")
        setImageData("")
        setSrcImg("")
        setImagePreview("")
        setEdit(false)
        setCurrentStaff("")
        setUserID("")
    }

    const handleClose = () => {
        setAddModal(false)
        handleClear()
    }

    const handleCloseAssignModal = () => {
        getStaffList()
        setAssignModal(false)
    }

    const handleOpenAddModal = (e, data) => {
        if (!data) {
            handleClear()
        }
        setAddModal(true)
    }

    return (
        auth.role === "company" || auth.role === "agent" ?
            <div className="ms-md-0 ms-4">
                <div className="row">
                    <div id="staff-header-btn" className="row">
                        {/* <div className="staff-cal btn button text-bg-primary me-2 px-1">
                        <Icon name="calendar alternate outline" size='large' />
                    </div> */}
                        {auth.role !== "staff" && <div onClick={() => setAddModal(true)} className="staff-cal ui circular button text-bg-primary">
                            <Icon name="add user" />
                            Add Agent
                        </div>}
                        <Modal
                            show={addModal}
                            onHide={handleClose}
                            centered={true}
                            size="lg"
                            contentClassName="staff-modal"
                        >
                            <Modal.Body className='p-5'>
                                <StaffAddForm
                                    {...{
                                        add_img,
                                        setResult,imageName,
                                        setImageData, setImageName,
                                        setSrcImg,srcImg,
                                        imagePreview, handleImage,
                                        imageData, handleSubmit, handleClose,
                                        name, setName,
                                        post, setPost,
                                        mobile, setMobile,
                                        email, setEmail,
                                        userID
                                    }} />
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={assignModal}
                            onHide={handleCloseAssignModal}
                            centered={true}
                            size="lg"
                            contentClassName="agent-modal"
                        >
                            <Modal.Body className='p-5'>
                                <AssignStaffLeadTable leadList={leadList} refresh={getStaffList} fkUser={fkUser} getLeadList={getLeadList} currentStaff={currentStaff} close={handleCloseAssignModal} title="Assign Leads" />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className="row">
                    {staffList && staffList.map((data, index) =>
                        <StaffCard key={index} edit={handleEdit} refresh={getStaffList} open={handleOpenAssignModal} user={data?.first_name || data?.fkStaff?.first_name || ""} img={data?.image || data?.fkStaff?.image || ""} role={data?.post || data?.fkStaff?.post || ""} data={data?.fkStaff? data?.fkStaff:data} />
                    )}

                </div>
            </div> :
            <Navigate to="/" />
    )
}

export default Staff