// import axios, { axiosPrivate } from "../api/axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useAgentServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get dashboard data
    const getAgentDashboard = async (data) => {
        const response = await axiosPrivate.get("/agent/dashboard/", {
            params:{...data}
        })
        return response.data
    }

    //get staff list
    const getAgentStaffList = async () => {
        const response = await axiosPrivate.get("/agent/staff_list/")
        return response.data
    }

    //get staff details
    const getAgentStaffDetails = async (id) => {
        const response = await axiosPrivate.get("/agent/staff_details/" + id + "/")
        return response.data
    }

    //get lead list
    const getAssignedLeadList = async (data) => {
        const response = await axiosPrivate.get("/agent/lead_list/", {
            params: { ...data }
        })
        return response.data
    }

    //get leads list to assign
    const getStaffAssignLead = async (id, choice) => {
        const response = await axiosPrivate.get("/agent/staff_assign_lead/" + id + "/", {
            params: { "choice": choice }
        })
        return response.data
    }

    //assign leads to staffs
    const postStaffAssignLead = async (id, data) => {
        const response = await axiosPrivate.post("/agent/staff_assign_lead/" + id + "/", data)
        return response.data
    }

    //assign task to staffs
    const getStaffTaskListId = async (id) => {
        const response = await axiosPrivate.get("/agent/staff_tasklist/all/"+id+"/")
        return response.data
    }

    //assign task to staffs
    const getStaffTaskListIncomplete = async (id) => {
        const response = await axiosPrivate.get("/agent/staff_tasklist/incomplete/"+id+"/")
        return response.data
    }

    //assign task to staffs
    const postStaffTaskList = async (data) => {
        const response = await axiosPrivate.post("/agent/task_list/staff_assign/", data)
        return response.data
    }

    //edit task to staffs
    const putStaffTaskList = async (id,data) => {
        const response = await axiosPrivate.put("/agent/task_list/" + id + "/edit/", data)
        return response.data
    }

    //delete task to staffs
    const deleteStaffTaskList = async (id) => {
        const response = await axiosPrivate.delete("/agent/task_list/" + id + "/edit/")
        return response.data
    }

    //get tasklist of agent
    const getAgentTaskList = async () => {
        const response = await axiosPrivate.get("/agent/task_list/company_assign/")
        return response.data
    }

    //update task remark or status
    const updateAgentTaskSR = async (id,data) => {
        const response = await axiosPrivate.put("/agent/task_list/"+id+"/update/",data)
        return response.data
    }


    //returns
    return {
        getAgentDashboard,
        getAgentStaffList,
        getAgentStaffDetails,
        getAssignedLeadList,
        getStaffTaskListIncomplete,
        getStaffAssignLead,
        getStaffTaskListId,
        getAgentTaskList,
        postStaffAssignLead,
        postStaffTaskList,
        putStaffTaskList,
        updateAgentTaskSR,
        deleteStaffTaskList,
    }
}
export default useAgentServices