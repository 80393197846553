import { Button, Modal } from "react-bootstrap"
import { BsPersonAdd } from 'react-icons/bs';
import "./Customer.css"
import CustomerAddForm from "./components/CustomerAddForm";
import { useState } from "react";
import useCustomerServices from "../../services/customerServices";
import { useEffect } from "react";
import CustomerTable from "./components/CustomerTable";


import { Dropdown, Icon } from 'semantic-ui-react'
import { DatePicker } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import { Pagination } from "../../components/pagination/Pagination";
import {AiOutlineSearch} from 'react-icons/ai'
import { useReducer } from "react";




const initialState = {
    date: '',
    from: '',
    to: '',
    dateType: 'Filter',
    showSearch: false,
    showPicker: false,
    searchQuery: "",
    isSearch: false,
  }
  
  const reducer = (state, action) => {
    switch (action.type) {
      case 'date':
        return { ...state, date: action.payload, from: '', to: '' };
      case 'from':
        return { ...state, from: action.payload, date: '' };
      case 'to':
        return { ...state, to: action.payload, date: '' };
      case 'dateType':
        return { ...state, dateType: action.payload };
      case 'showPicker':
        return { ...state, showPicker: action.payload };
      case 'searchQuery':
        return { ...state, searchQuery: action.payload }
      case 'isSearch':
        return { ...state, isSearch: action.payload };
  
      case 'reset':
        return { ...initialState };
    }
  }
const Customer = () => {
    const [show, setShow] = useState(false)
    const [statusList, setStatusList] = useState("")
    const [customerList, setCustomerList] = useState("")
    const [crntpg, setCrntpg] = useState(1)
    const [limit, setLimit] = useState(0)
    const [item, setItem] = useState(25)

    const [filter, setFilter] = useState("Filter")
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")

    const [filters,dispatch]=useReducer(reducer,initialState)

    const handleClose = () => {
        setShow(false)
    }

    const { getAllCustomers, getActiveStatus } = useCustomerServices()

    useEffect(() => {
        getStatusList()
    }, [crntpg])
    useEffect(()=>{
        
        getCustomerList()
    },[filters.isSearch])
    useEffect(() => {
        if (filters.from == "" && filters.to == "") {
            getCustomerList();
        }
      }, [
        filters.date
      ])

    const getCustomerList = async () => {
        const form = {
            date: filters.date,
            to: filters.to,
            from: filters.from,
            page: crntpg,
            search: filters.isSearch ? filters.searchQuery : ""
      
          }
        // let form= {...data}
        // form.page = crntpg
        // form.item = item
        const response = await getAllCustomers(form&&form)
        if (response !== null) {
            setCustomerList(response?.data?.customer)
            setLimit(response?.data?.total_page)
        }
    }

    const getStatusList = async () => {
        const response = await getActiveStatus()
        if (response !== null) {
            setStatusList(response.data)
        }
    }

    const handleFilter = (data) => {
        dispatch({ type: 'dateType', payload: data })
        // setFilter(data)
        if (data === 'Date' || data === 'Range') {
            dispatch({ type: "showPicker", payload: true })
          }
        if (data == "Today") {
            let temp = { date: format(new Date(), 'yyyy-MM-dd') }
            dispatch({ type: 'date', payload: format(new Date(), 'yyyy-MM-dd') })

            // getCustomerList(temp)


        }
        else if (data == "Yesterday") {
            let date = new Date();
            date.setDate(date.getDate() - 1);
            let temp = { date: format(date, 'yyyy-MM-dd') }
            dispatch({ type: 'date', payload: format(date, 'yyyy-MM-dd') })

            // getCustomerList(temp)


        }
    }
    const handleDate = async (e) => {
        let temp = { date: format(e['$d'], 'yyyy-MM-dd') }
        dispatch({ type: 'date', payload: format(e['$d'], 'yyyy-MM-dd') })
        handlePickerClose();


        // getCustomerList(temp)

    }

    const handleDateRange = async (e) => {
        e.preventDefault()
        // let temp = { from: format(fromDate, 'yyyy-MM-dd'), to: format(toDate, 'yyyy-MM-dd') }
        getCustomerList()
        handlePickerClose();


    }

    const handleSearch = () => {
        if (filters.searchQuery != "") {
          dispatch({ type: 'isSearch', payload: true })
        }
        else {
            getCustomerList()
        }
      };
      const handlePickerClose = () => {
        dispatch({ type: "showPicker", payload: false })
      };


    return (
        // auth.role === "company" || auth.role === "agent" ?
        <div className="">
            <div className="row pe-1  align-items-center">
                <div className="col-md-2 col-4 px-0 ms-0 row">
                    <div className="col-md col my-3 ms-md-0 ms-3 ps-0 pe-0">
                        <Dropdown id="dropdown-basic-button"
                            className='btn btn-sm btn-primary'
                            text={filters.dateType}>
                            <Dropdown.Menu className='mt-2 p-3'>
                                <Dropdown.Item onClick={() => handleFilter("Today")}>Today</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleFilter("Yesterday")}>Yesterday</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleFilter("Date")}>Specific day</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleFilter("Range")}>Date Range</Dropdown.Item>
                            </Dropdown.Menu></Dropdown>
                    </div>
                </div>
                {( filters.dateType != "Filter" || filters.isSearch) &&
          <div className="col-2 ms-1 pe-0 ps-0 col-md-1 clear-filter my-3">
            <Button onClick={() => dispatch({ type: 'reset' })} variant="danger" size='sm'>
              {window.innerWidth > 768 ? 'Reset' : <Icon name='x' />}
            </Button>
          </div>}
          <Modal
          show={filters.showPicker}
          onHide={handlePickerClose}
          centered
          size="sm"
          contentClassName="upload-modal"
        >
          <Modal.Body className="p-4">
            {filters.dateType === "Date" &&
              <>
                <div className="col-md-12 my-3 ps-md-0">
                  <DatePicker
                    className='lead-datepicker col-12 px-2'
                    onChange={(e) => handleDate(e)}
                    format="DD-MMM-YYYY"
                  />
                </div>
              </>}
            {filters.dateType === "Range" &&
              <>
                <div className="col-md-12 my-3 ps-md-0">
                  <DatePicker
                    className='lead-datepicker col-12 px-2 '
                    onChange={(e) => dispatch({ type: 'from', payload: format(e['$d'], 'yyyy-MM-dd') })}

                    format="DD-MMM-YYYY"
                  />
                </div>
                <div className="col-md-12 my-3 ps-md-1">
                  <DatePicker
                    className='lead-datepicker col-12 px-2 px-md-0'
                    onChange={(e) => dispatch({ type: 'to', payload: format(e['$d'], 'yyyy-MM-dd') })}
                    format="DD-MMM-YYYY"
                  />
                </div>

                <div className='col-md-12 my-3 ps-md-1'>
                  <button className='btn btn-pill btn-outline-primary col-12 px-2 px-md-0' onClick={(e) => handleDateRange(e)}>search</button>
                </div>


              </>
            }
          </Modal.Body>
        </Modal>
                {/* {filter === "Date" &&
                    <>
                        <div className="col-md-2 my-3">
                            <DatePicker
                                className='lead-datepicker col-12 px-2 px-md-0'
                                onChange={(e) => handleDate(e)}
                                format="DD-MMM-YYYY"
                            />
                        </div>
                    </>}
                {filter === "Range" &&
                    <>
                        <div className="col-md-2 my-3">
                            <DatePicker
                                className='lead-datepicker col-12 px-2 px-md-0'
                                onChange={(e) => setFromDate(e['$d'])}
                                format="DD-MMM-YYYY"
                            />
                        </div>
                        <div className="col-md-2 my-3 ps-md-1">
                            <DatePicker
                                className='lead-datepicker col-12 px-2 px-md-0'
                                onChange={(e) => setToDate(e['$d'])}
                                format="DD-MMM-YYYY"
                            />
                        </div>
                        <div className='col-md-1 my-3 ps-md-1'>
                            <button className='btn btn-pill btn-outline-primary col-12 px-2 px-md-0' onClick={(e) => handleDateRange(e)}>search</button>
                        </div>


                    </>
                } */}
                <div id="" className={`${( filters.dateType != "Filter") || filters.isSearch ? 'col-md' : 'col-md'} col-12 my-3 me-3 text-md-end align-items-center d-md-flex justify-content-end text-center`}>
          {window.innerWidth > 768 ? <>
            <div class={"ui action input search-bar show"}>
              <input value={filters.searchQuery} onChange={(e) => dispatch({ type: "searchQuery", payload: e.target.value })} type="text" placeholder="Search..." class="rounded-input" />
              <button onClick={handleSearch} class="ui button rounded-button"><AiOutlineSearch/></button>
            </div>

            {/* <div className="col-4 ms-1 me-2 pe-0 ps-0 col-md-1 clear-filter my-3">
              <Button className='search-trigger col-12' onClick={() => dispatch({ type: 'showSearch' })} variant={filters.showSearch ? "danger" : "primary"} size='sm'>
                <Icon name={filters.showSearch ? 'arrow right' : 'search'} size='' />
              </Button>
            </div> */}
          </>
            :
            <div class={"ui action input search-bar show mb-3"}>
              <input value={filters.searchQuery} onChange={(e) => dispatch({ type: "searchQuery", payload: e.target.value })} type="text" placeholder="Search..." class="rounded-input" />
              <button onClick={handleSearch} class="ui button rounded-button"><AiOutlineSearch /></button>
            </div>
          }
        </div>
            </div>
            <div className="row me-2">
                <div className="card customer-table-card">
                    <div className="card-body table-responsive pb-5">
                        <table className="table table-striped pb-5 mb-5">
                            <thead className="border-4">
                                <tr className="">
                                    <th>Sl No.</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Call</th>
                                    <th>E-mail</th>
                                    <th className="pe-5 px-0">Date</th>
                                    <th className="text-center">Payment</th>
                                    <th className="text-center">Status</th>
                                    <th className="pe-4">Active</th>
                                </tr>
                            </thead>
                            <tbody className="customer-table">
                                <tr></tr>
                                <tr><td></td></tr>
                                {customerList && customerList.map(
                                    (data, index) => {
                                        return (
                                            <CustomerTable key={index} index={(item*(crntpg-1))+index} data={data} statusList={statusList} refresh={getCustomerList} />
                                        )
                                    }
                                )
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className=""> */}
                    <div onClick={() => setShow(true)} className={"button add-position " + (window.innerWidth>768?'lap-view':'mobile-view')}>
                        <div className="customer-add">
                            <span className="card mouse-pointer p-3 d-inline">
                                <BsPersonAdd className="px-0" size="30px" /> Add Customer
                            </span>
                        </div>
                    </div>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        centered={true}
                        size="lg"
                        contentClassName="lead-modal"
                    >
                        <Modal.Body className='p-5'>
                            <CustomerAddForm handleClose={handleClose} refresh={() => getCustomerList()} />
                        </Modal.Body>
                    </Modal>
                    {/* </div> */}
                </div>
            </div>
            {limit>1&&<Pagination {...{crntpg,setCrntpg,limit}}/>}
        </div>
        // :
        // <Navigate to="/" />
    )
}

export default Customer