import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import useAuth from "../../hooks/useAuth";
import useBaseServices from "../../services/baseServices";

function ExtensionForm({ setNumberModal, userID }) {
    const [agentNumber, setAgentNumber] = useState("");
    const [newNumber, setNewNumber] = useState("");
    const [newExtension, setNewExtension] = useState("");
    const [extensionList, setExtensionList] = useState("");
    const [extList, setExtList] = useState("");

    const { auth } = useAuth();

    useEffect(() => {
        handleNumberShow();
    }, []);

    const {
        getAssignAgentNumber,
        getActiveAgentNumbers,
        getAssignedAgentNumber,
        postAssignAgentNumber,
        deleteUnassignAgentNumber,
    } = useBaseServices();

    const handleNumberShow = async () => {
        setAgentNumber("");
        setNewNumber("");
        setNewExtension("");
        setExtensionList("");
        if (auth.role==='company'){
            try {
                const response = await getAssignAgentNumber(userID);
                if (response?.success) {
                    setAgentNumber(response?.data);
                }
            } catch {}
            try {
                const response = await getActiveAgentNumbers();
                if (response?.success) {
                    setExtensionList(response?.data);
                }
            } catch { }
        }
        else{
            try{
                const response = await getAssignedAgentNumber()
                if (response?.success){
                    setAgentNumber(response?.data)
                }
            } catch{}
        }
    };

    const getExtList = (e) => {
        setNewNumber(e.target.value);
        var temp = [];
        {
            extensionList &&
                extensionList.map((data) => {
                    e.target.value == data.agent_number && temp.push(data.extension);
                });
        }
        setExtList(temp);
        // console.log(temp);
    };

    const handleNumberClose = () => {
        setNumberModal(false);
    };

    const handleNumberSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!newExtension) {
                Swal.fire("", "Select an extension", "error");
            }
            const datas = JSON.stringify({
                caller_id: newNumber,
                extension: newExtension,
            });
            const response = await postAssignAgentNumber(userID, datas);
            if (response?.success) {
                Swal.fire("", response?.message, "success");
                setNewNumber("");
                setNewExtension("");
                handleNumberShow();
            } else {
                Swal.fire("", response?.message, "error");
            }
        } catch { }
        Swal.fire("", "Something went wrong!!", "error");
    };

    const deleteAgentExtension = async () => {
        try {
            const response = await deleteUnassignAgentNumber(userID);
            if (response?.success) {
                Swal.fire("", "Agent Number Unassigned", "success");
                handleNumberShow();
            } else {
                Swal.fire("", response?.message, "error");
            }
        } catch { 
            Swal.fire("", "Something went wrong!!", "error");
        }
    };
    return (
        <Form onSubmit={handleNumberSubmit}>
            <div className="p-2 pb-1">
                <h3>Agent Number</h3>
                <div className="row my-3">
                    {agentNumber ? (
                        <div className="row d-flex align-items-center">
                            <h5 className="ps-5 text-success col-md-6 col-12">
                                {agentNumber.agent_number}
                            </h5>
                            <h5 className="ps-5 text-success col-md-4 col-10">
                                {agentNumber.extension}
                            </h5>
                            {auth.role === "company" && (
                                <div className="col-1">
                                    <span
                                        className="btn btn-danger"
                                        onClick={deleteAgentExtension}
                                    >
                                        <MdDelete size="18px" />
                                    </span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <h5 className="ms-5 text-warning">Number Not Assigned</h5>
                    )}
                </div>
                {auth.role === "company" && (
                    <div className="row mb-0">
                        <div className="col-12 me-2">
                            <hr />
                        </div>

                        <div className="px-1 my-2 col-md-7 col-12">
                            <Form.Group className="" controlId="">
                                <Form.Label className="">Agent Number</Form.Label>
                                <Form.Select
                                    className={"mouse-pointer type-dropdown "}
                                    disabled={agentNumber ? true : false}
                                    size="md"
                                    onChange={(e) => getExtList(e)}
                                >
                                    <option className="menu" value="">
                                        Select
                                    </option>
                                    {extensionList &&
                                        extensionList.map((data, index) => (
                                            <option className="menu" value={data?.agent_number}>
                                                {data?.agent_number}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="px-1 my-2 col-md-5 col-12">
                            <Form.Group className="" controlId="">
                                <Form.Label className="">Extension</Form.Label>
                                <Form.Select
                                    className={"mouse-pointer type-dropdown "}
                                    disabled={!agentNumber && extList ? false : true}
                                    size="md"
                                    onChange={(e) => setNewExtension(e.target.value)}
                                >
                                    <option className="menu" value="">
                                        Select
                                    </option>
                                    {extList &&
                                        extList.map((data, index) => (
                                            <option className="menu" value={data}>
                                                {data}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                )}
                <div className="my-2">
                    <div className="row text-end">
                        <div className="col-md-5 col-12"></div>
                        <div
                            onClick={handleNumberClose}
                            className="col-md-3 col btn btn-pill btn-danger mx-2"
                        >
                            Cancel
                        </div>
                        {auth.role === "company" && (
                            <button
                                onClick={(e) => handleNumberSubmit(e)}
                                className="col-md-3 col btn btn-pill btn-primary"
                                disabled={newExtension ? false : true}
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default ExtensionForm;
