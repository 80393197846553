import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useReportServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get Report lead monthly chart
    const getReportLeadChart = async(user) => {
        const response = await axiosPrivate.get("/"+user+"/report_lead/monthly_chart/")
        return response.data
    }

    //get Report lead list
    const getReportLeadList = async(user) => {
        const response = await axiosPrivate.get("/"+user+"/report_lead/lead_list/")
        return response.data
    }

    //get Report staff monthly chart
    const getReportStaffChart = async(id, user) => {
        const response = await axiosPrivate.get("/"+user+"/report_staff/monthly_chart/"+id+"/")
        return response.data
    }

    //get Report staff lead list
    const getReportStaffLeadList = async(id, user) => {
        const response = await axiosPrivate.get("/"+user+"/report_staff/lead_list/"+id+"/")
        return response.data
    }

    return{
        getReportLeadChart,
        getReportLeadList,
        getReportStaffChart,
        getReportStaffLeadList
    }
}

export default useReportServices