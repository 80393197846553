import { Form } from "react-bootstrap"
import { Link } from "react-router-dom"

const RegisterForm = (props) => {
    const { handleSubmit, setCompanyName, setUserName, setPassword, setPasswordConfirm, setMobile, setEmail, handleImage, imagePreview } = props
    return (
        <div className="pt-3">
            <Form onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <div className="col-md-4 mb-4 ps-md-1 ms-md-0 ms-5 ps-5">
                        <div className="">
                            <label htmlFor="uploadCompanyImg" className='register-user-img'>

                                <img src={imagePreview} alt="" className="w-100"/>

                            </label>
                            <input id='uploadCompanyImg' onChange={(e) => handleImage(e)} hidden className='form-control' type="file" accept='image/*' />

                        </div>
                    </div>
                    <div className="col-md-8 col-12">
                        <Form.Group className="mt-0 registerUser" controlId="companyName">
                            <Form.Control onChange={(e) => setCompanyName(e.target.value)} className="register-text" placeholder="Company Name" />
                        </Form.Group>
                        <Form.Group className="mb-4 registerUser" controlId="userName">
                            <Form.Control onChange={(e) => setUserName(e.target.value)} className="register-text" type="text" placeholder="Username" />
                        </Form.Group>
                    </div>
                </div>
                <Form.Group className="mt-0 registerUser" controlId="userMobile">
                    <Form.Control onChange={(e) => setMobile(e.target.value)} className="register-text" type="tel" placeholder="Mobile Number" />
                </Form.Group>
                <Form.Group className="mb-4 registerUser" controlId="userEmail">
                    <Form.Control onChange={(e) => setEmail(e.target.value)} className="register-text" type="email" placeholder="Email ID" />
                </Form.Group>
                <Form.Group className="mb-4 registerUser" controlId="userPassword">
                    <Form.Control onChange={(e) => setPassword(e.target.value)} className="register-text" type="password" placeholder="Password" />
                </Form.Group>
                <Form.Group className="mb-4 registerUser " controlId="passwordConfirm">
                    <Form.Control onChange={(e) => setPasswordConfirm(e.target.value)} className="register-text mb-2" type="password" placeholder="Confirm Password" />
                </Form.Group>
                <Form.Group className="mb-4 registerUser pt-4 " controlId="registerSubmit">
                    <button className="btn login-submit mx-md-5 col-md-8 col-12 p-3">Create Account</button>
                </Form.Group>
            </Form>
            <div className="register-signin">
                Already a user ? <Link className="" to="/login">Sign In</Link>

            </div>
        </div>
    )
}

export default RegisterForm