import React, { useState } from 'react'
import { Dropdown, Icon} from 'semantic-ui-react'
import useCompanyServices from '../../../services/companyServices'
import StaffItem from './StaffItem'

const AgentStaffTable = (props) => {

    const [selected, setSelected] = useState('all')
    const { staffList, currentAgent,
        // fkUser,
         getStaffList } = props

    const { postStaffAssignAgent } = useCompanyServices()

    const handleAssignStaff = async (staff_id) => {
        try {
            const data = JSON.stringify({
                staff_id: staff_id,
            })
            const response = await postStaffAssignAgent(currentAgent, data)
            if (response.success) {

                return true;
            }
        } catch (err) { console.log(err) }
    }

    const options = [
        { key: 1, text: 'All', value: "all" },
        { key: 2, text: 'Assign ', value: "assigned" },
        { key: 3, text: 'Unassign', value: "unassigned" },
    ]
    // const handleSearch = () => {

    // }

    const handleSelect = (event, data) => {
        setSelected(data.value)
        if (data.value === 'all') {
            getStaffList(null,null)
        } else if (data.value === 'assigned'){
            getStaffList(null,data.value)
        } else if (data.value === 'unassigned') {
            getStaffList(null,data.value)
        }
    }
    
    return (
        <div className='position-relative'>
            <div className="asign-lead-close">
                <button className='btn' onClick={props.close} ><Icon name="close" size="large" /></button>
            </div>
            <div className="row">
                <div className="h3 fw-bolder col-9">{props.title}</div>
                {/* <div className="col-md-2"> */}
                    {/* <Search
                        input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                        placeholder="Search Here..."
                        // onResultSelect={}
                        size='small'
                        onSearchChange={handleSearch}

                    /> */}
                {/* </div> */}
                <div className="col-md-2">
                    <Dropdown
                        selection
                        fluid={true}
                        value={selected}
                        options={options}
                        onChange={handleSelect} />
                </div>
            </div>

            <div className="row my-3">
                <div className="assign-table-container p-4">
                    <div>
                        <div className="row py-2 assign-table-header">
                            <div className="col-2">Sl.No</div>
                            <div className="col-4">Name</div>
                            <div className="col-4">Phone Number</div>
                            {/* <div className="col-3">Interested</div> */}
                            <div className="col-2">Assign</div>
                        </div>
                    </div>
                    <div className="my-2 assign-table-body">
                        {staffList && staffList.map(
                            (data, index) => 
                            <StaffItem 
                                handleAssignStaff={handleAssignStaff} 
                                currentAgent={currentAgent} 
                                index={index} 
                                key={index} 
                                data={data} 
                                selected={selected} 
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentStaffTable