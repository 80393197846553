import { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../api/axios"
import useAuth from "./useAuth"
import Swal from "sweetalert2";

const clearAuth =()=>{
    localStorage.setItem("accessToken","");
    localStorage.setItem("userName","");
    localStorage.setItem("passWord","");
    localStorage.setItem("role","");
}


const useAxiosPrivate = () => {
    const { auth,setAuth } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {

        axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Token ${auth?.accessToken}`;

                }
                return config;
            }, (error) => {
               return Promise.reject(error);
            }
        );

        axiosPrivate.interceptors.response.use(
            response => {
                return response
            }, (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    return axiosPrivate(prevRequest);
                }
                // if (error?.response?.status === 401) {
                //     setAuth("");
                //     clearAuth();
                //     navigate("/login");
                //     Swal.fire("Unauthorized","Please re-login","error")

                    // redirect to login page
                //   }
                  if (error?.response?.status === 404 && error.response.config.method === "post") {
                    console.log()
                    Swal.fire("404","Page not found","error")
                  }
                return Promise.reject(error)
            })
    }, [auth])
    return axiosPrivate
}

export default useAxiosPrivate;

