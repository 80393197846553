import React, { useEffect } from 'react'
import { HiPencil } from 'react-icons/hi'
import { MEDIA_URL } from '../../../api/axios'


export const TaskCard = (props) => {
  const {
    setData2,getStaffTaskListId,setTaskData,user,setEditTask,
    getAgentTaskWithId,setTEVC,auth,setEditId,setViewTask
    ,setAgentId,setNewT,newT,setNoTask,StaffData,setFormId,} = props
    
    const handleClick = async (txt) =>{
      setAgentId(StaffData.fkUser)
      try{
        let res
        if(auth.role==="agent" && StaffData.task>0){
          res = await getStaffTaskListId(StaffData.fkUser)
    }
      else if(auth.role === "company" && StaffData.task>0){
        res = await getAgentTaskWithId(StaffData.fkUser)
      }
      if(res?.success){
        if(txt ==="edit" && StaffData.task !== StaffData.completed){
          var f = res.data.filter((x)=>{
            return x.task_status !== "completed" 
          })
          setTaskData([...f])
        }else{
        setTaskData(res.data)}
      }
      setData2(StaffData)
      setFormId(0)
      setEditId(res?.data[0]?.id)
      if(StaffData.task<1 || StaffData.task === StaffData.completed  && txt === "edit"){
        setNoTask(true)
        setTEVC(true)
      }else if(StaffData.task<1){
        setNoTask(true)
      }else{
        if(txt === "edit"){
          setEditTask(true)
          setNewT(
            {[newT.name]:res?.data.task,
            [newT.desc]:res?.data.task_descriptions,
            [newT.last_data]:res?.data.last_data,
            [newT.fk_user_to_assign]:res?.data.fk_user_to_assign}
            )
      }else if(txt === "view" ){
        setViewTask(true)
      }
    }
    }catch(err){
      console.log(err)}
    }
    
  return (
    <div className='task-card col-md-12 col-12 py-1'>
          <div className='task-top d-flex ps-4 py-2 rounded-3'>
            <div className='d-flex  flex-row'><img className='rounded-circle' width={60} src={StaffData?.image!==null?`${MEDIA_URL+StaffData?.image}`:user}/></div>
            <div className='ps-3 '><div className='cardName pt-3 pe-5 d-flex'>{StaffData?.first_name}</div><div className=' cardName2'>{StaffData?.post}</div></div>
            <div className='card-imgc'><div onClick={()=>handleClick("edit")} className='card-img btn border border-primary text-primary rounded-3'><HiPencil className='h5 mb-0'/></div></div>
        </div>
        <div className='task-btm pt-2 '>
          <div className=' card-btm  d-flex '>
            <div className='d-flex flex-column '><div className='crd-btm-num text-center'>{StaffData?.task||'0'}</div><div className='crd-btm-txt'>task</div></div>
            <div className='d-flex flex-column '><div className='crd-btm-num text-center'>{StaffData?.completed||'0'}</div><div className='crd-btm-txt'>completed</div></div>
            <div className='d-flex flex-column '><div className='crd-btm-num text-center'>{StaffData?.pending||'0'}</div><div className='crd-btm-txt'>pending</div></div>
          </div>
          <div onClick={()=>handleClick("view")} className='text-center card-btn btn border-primary my-3 rounded-5 '>View Details</div>
        </div>
    </div>
  )
}