import React, { useState } from "react";
import "./Notification.css";
import NotificationCard from "./components/NotificationCard";

const Notification = ({ notificationList, setShowReminder, setReminderId }) => {
    const handleClick = (id)=>{
        setReminderId(id)
        setShowReminder(true)
    }

    return (
        <div className="outline-card col-12">
            {notificationList ?
                notificationList.map((data, index) => {
                    return (
                        <NotificationCard data={data} key={index} handleClick={()=>handleClick(data?.id)} />                      
                    )
                })
            :
                <div className="null-card col-12">No available Notifications</div>
            }
        </div>
    );
};

export default Notification;
