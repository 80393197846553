import "./Task.css";
import React, { useState } from "react";
import priority from "../../assets/images/icons/Prioritize.png";
import useAuth from "../../hooks/useAuth";
import { TaskCard } from "./components/TaskCard";
import user from "../../assets/images/imgs/user.png";
import useCompanyServices from "../../services/companyServices";
import useAgentServices from "../../services/agentServices";
import useStaffServices from "../../services/staffServices";
import { Nav, Tab } from "react-bootstrap";
import { useEffect } from "react";
import AgentTaskList from "./components/AgentTaskList";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { TaskForm } from "./components/TaskForm";

export const Task = () => {
  // task card buttons
  const [addTask, setAddTask] = useState(false);
  const [viewTask, setViewTask] = useState(false);
  const [editTask, setEditTask] = useState(false);

  // data states
  const [tasks, setTasks] = useState(); // all tasks of an agent | staff
  const [stafs, setStafs] = useState([]); // all staff | agent 's
  const [staff, setStaff] = useState([]); // filetered stafs 
  const [taskData , setTaskData] = useState() // tasks of single
  const [data2, setData2] = useState([]); // task list

  // condition states
  const [agentId, setAgentId] = useState(0);
  const [editId, setEditId] = useState();
  const [formId, setFormId] = useState(1);
  const [key, setKey] = useState("tab1");
  const [noTask, setNoTask] = useState(false);
  const [tEVC, setTEVC] = useState(false); // task edit view check

  const [newT, setNewT] = useState({
    name: "",
    desc: "",
    last_date: "",
  });

  // Agent services
  const { getAgentStaffList, postStaffTaskList ,
        putStaffTaskList, getStaffTaskListId,
        getAgentTaskList,deleteStaffTaskList} = useAgentServices();

  // company services
  const {
    putCompanyTask,getAgentListToday,
    delCompanyTask,postCompanyTask,
    getAgentTaskWithId,} = useCompanyServices();

  // staff services
  const {
    getStaffTaskList,
  } = useStaffServices()

  const { auth } = useAuth();


  // useEffect to call all task
  useEffect(() => {
    if(auth.role === "staff"){
      setKey("tab2")
      setTaskData()}
    getTask();
  }, [viewTask,addTask,key]);
  
  //get all task function
  const getTask = async () => {
    try {
      var response1;
      var response2;
      if (auth.role === "company") {
        response2 = await getAgentListToday();
      } else if (auth.role === "agent" && key === "tab1") {
        response2 = await getAgentStaffList()
      }else if (auth.role === "agent" && key === "tab2"){
        response1 = await getAgentTaskList()
      }
      else if (auth.role === "staff"){
        response1 = await getStaffTaskList()
      }
      if(response1?.success && response1.data.length>0){
        setTasks(response1?.data)
      }
      if (response2?.success) {
        if (auth.role === "agent") {
          let a = [];
          response2?.data.map((x) => {
            let g = x.fkStaff
            g.task = x.fkStaff.report.total_task 
            g.completed = x.fkStaff.report.total_completed_task
            g.pending = x.fkStaff.report.total_not_completed_task
            a.push(g);
          });
          setStafs(a);
        } else if(auth.role === "company"){
          let a = [];
          response2?.data.map((x) => {
            let g = x
            g.task = x.report.total_task 
            g.completed = x.report.total_completed_task
            g.pending = x.report.total_not_completed_task
            a.push(g);
          });
          setStafs(a);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // function to accept input values
  const handleChange = (e) => {
    setNewT((newT) => ({ ...newT, [e.target.name]: e.target.value }));
  };

  // confirm alert
  const confirmDelete =async (e) =>{
    Swal.fire({
      title: 'Do you want to delete the Task?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't delete`,
    }).then(async(result) => {
      if (result.isConfirmed) {
        await handleDelete(e)
        Swal.fire('Deleted!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('task not deleted', '', 'info')
      }
    })
  }

  const handleDelete = async (e) =>{
    e.preventDefault()
    try{
      let res
      if(auth.role === "company"){
        res = await delCompanyTask(editId)
      }else if(auth.role === "agent"){
        res = await deleteStaffTaskList(editId)
      }
      setFormId(0)
      if(res?.success || res?.message === "delete Successfuly"){
        let resp
        if(auth.role === "company"){
        resp = await getAgentTaskWithId(agentId)
        }else if(auth.role === "agent"){
        resp = await getStaffTaskListId(agentId)
        }
        if(resp.data.length===0){
          handleClose()
          getTask()
        }else{
          setTaskData(resp?.data)
          setEditId(resp?.data[0]?.id)
          getTask()
        return 0
        }
      }
    }catch(err){
      Swal.fire('task not deleted. Something went wrong', '', 'error')
    }
  }


  // call when submit is clicked
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var res;
      const data = JSON.stringify({
        task: newT.name,
        task_descriptions: newT.desc,
        fk_user_to_assign: agentId,
        last_date: newT.last_date,
        task_status: 'not_completed'
      });
      if(addTask){
        if (auth.role === "company") {
          res = await postCompanyTask(data);
        } else if (auth.role === "agent") {
          res = await postStaffTaskList(data);
        }
      }else if(editTask){
        if (auth.role === "company") {
          res = await putCompanyTask(editId,data);
        } else if (auth.role === "agent") {
          res = await putStaffTaskList(editId,data);
        }
      }
      getTask();
      handleClose()
      if(res?.success){
        Swal.fire("", res?.message, "success");
      }
      else{
        Swal.fire("", res?.message, "error");
      }
    } catch (err) {
      console.log(err)
      Swal.fire("", err?.response?.data?.message||"Something went wrong", "error");
      console.log(err);
    }
  };
  

  // clear all values when closed
  const handleClose = () => {
    setViewTask(false);
    setAddTask(false);
    setEditTask(false);
    setFormId(0);
    setAgentId()
    setTEVC(false)
    setNoTask(false)
    setNewT({
      ...newT, name: "",
      desc: "",last_date: "",
      fk_user_to_assign:"",});
    };



  return (
    <div className="ms-md-0 ms-4">
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
        <div className="row">
          <div id="staff-header-btn border" className="row task-tab-main">
            {/* <div className="row border"> */}
            {auth.role === "agent" && (
              <Nav variant="pills" className=" ps-4 task-tab">
                <Nav.Item className="pt-2 px-md-3">
                  <Nav.Link
                    className="staff-cal text-end ui circular button py-3  px-md-5 "
                    eventKey="tab1"
                  >
                    Staff
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="pt-2 px-md-3">
                  <Nav.Link
                    className="staff-cal text-end ui circular button py-3  px-md-5 "
                    eventKey="tab2"
                  >
                    Tasks
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            )}
            {auth.role !== "staff" && key ==="tab1"&&<div
              onClick={() => setAddTask(true)}
              style={{ backgroundColor: "#3E66CD" }}
              className="staff-cal ui add-task circular button d-flex text-light"
            >
              <img src={priority} className="me-3 ms-1" width={20} />
              <div className="task-txt mt-1">Add Task</div>
            </div>}
          </div>
          <div className="row mt-5">
            <Tab.Content>
              <Tab.Pane eventKey="tab1" className="">
                <div className="row mt-2 me-3 me-md-0">
                  {stafs &&
                    stafs.map((StaffData, index) => (
                      // <div key={index}>
                      <div key={index} className='col-lg-4 col-md-6 my-3'>
                      <TaskCard
                        {...{
                          getAgentTaskWithId,setViewTask,
                          getStaffTaskListId,editId,
                          setData2,setEditId,setTEVC,
                          key,setNoTask,auth,setTaskData,
                          setAgentId,setNewT,newT,StaffData,
                          setFormId,user,setEditTask,
                        }}
                      />
                   </div>
                   ))}
                </div>
                <Modal
              show={viewTask || addTask || editTask || noTask}
              onHide={handleClose}
              centered={true}
              size={noTask?"lg":"xl"}
              contentClassName="staff-modal"
            >
              <Modal.Body className="p-0 m-0">
               {(editTask||addTask||viewTask)&&<TaskForm
                 {...{stafs,staff,data2,setEditId,
                    setStaff,formId,agentId,setAgentId,
                    handleSubmit,setNewT, addTask,newT,
                    setAddTask,handleChange,taskData,
                    setFormId,handleClose,user,editTask,
                    setEditTask,viewTask,setViewTask,
                    confirmDelete,noTask,tEVC,setNoTask
                  }}
                /> }
              {noTask&&<div className="text-center"><div className="h4 text-center py-5 my-5">{`No task ${tEVC?"to edit ":"added "} for this Staff!`}</div>
              <div onClick={()=>{handleClose()}} className="btn mb-5 form-btn2 rounded-4 px-5 ms-3">Cancel</div></div>}
              </Modal.Body>
            </Modal>
                {/* <Modal
              show={noTask}
              onHide={setNoTask(false)}
              centered={true}
              size="lg"
              contentClassName="staff-modal"
            >
              <Modal.Body className="p-0 m-0">
                <div>
                  Not Task Added for this agen
                </div>
              </Modal.Body>
            </Modal> */}
               
              </Tab.Pane>
              <Tab.Pane eventKey="tab2" className="">
                <div className="row mt-3">
                <AgentTaskList data={tasks} auth={auth} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
};
