import React, { useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { Form } from 'react-bootstrap'
import { BsDownload } from 'react-icons/bs'
import { useState } from 'react'
import useLeadServices from '../../../services/leadServices'
import { Dropdown } from 'semantic-ui-react'

const LeadUplodForm = (props) => {
    const { handleBulkSubmit, handleUploadClose, imagePreview, handleFile, previewText, uploadAgent, uploadStaff, setUserList } = props
    const [csvData, setCSVData] = useState("")
    var agentList = [];
    var staffList = [];

    useEffect(() => {
        getTemplate();
    }, [])

    const { getExcelFieldTypes } = useLeadServices()
    function handleDragOver(event) {
        event.preventDefault();
    }

    function handleDrop(event) {
        event.preventDefault();
        var files = event.dataTransfer.files;
        var fileInput = event.target;
        fileInput.files = files;
        //console.log(event)
        handleFile(event);
    }

    const getTemplate = async()=>{
        const response = await getExcelFieldTypes()
        setCSVData(response?.data)
    }

    const handleAgentSelect = (e, {value}) => {
        e.preventDefault();
        agentList = value
        // console.log(agentList);
    };

    const handleStaffSelect = (e, {value}) => {
        e.preventDefault();
        staffList = value
        // console.log(staffList);
    };

    const handleSubmit = (e)=>{
        var userList=agentList.concat(staffList);
        // setUserList(userList)
        userList = [userList,]
        handleBulkSubmit(e, userList)
        // console.log(userList)
    }

    return (
        <div className='mx-3'>
            <Form onSubmit={handleSubmit}>
                <div className="row my-2 align-items-center">
                    <div className="col h4 fw-bolder">New Upload</div>
                    <div className="btn col-md-2 col-5 btn-success btn-pill float-end lead-template">
                        <CSVLink data={csvData} filename={'lead_upload_template.csv'}>
                            <BsDownload size="15" strokeWidth={1}/> Template
                        </CSVLink>
                    </div>
                </div>
                <div className="row my-2 ms-3">
                    <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className="lead-image-upload text-center p-4 row">
                        <div className="mb-3">
                            <img className='uploadPreview' src={imagePreview} alt="" />
                        </div>
                        {previewText}
                        <div className="my-3">
                            <div>
                                <label htmlFor='leadImage' className='col-6 btn btn-outline-primary browse-btn'>Browse Files</label>
                                <input id='leadImage' onChange={(e) => handleFile(e)} hidden className='form-control' type="file" accept='.csv' />
                            </div>
                        </div>
                        <div className='col-12 row text-center ps-md-5'>
                                {uploadAgent&&<div className='col-md-6 px-md-4 my-md-0 my-1'>
                                <Dropdown
                                    placeholder="Agents"
                                    fluid
                                    multiple
                                    search
                                    selection
                                    scrolling
                                    className='lead-upload-dropdown'
                                    onChange={handleAgentSelect}
                                    options={uploadAgent}
                                />
                            </div>}
                            {uploadStaff&&<div className={` ${uploadAgent?'ps-md-4 col-md-6 text-end my-md-0 my-1':'text-center'}`}>
                                <Dropdown
                                    placeholder="Staffs"
                                    fluid={uploadAgent?true:false}
                                    multiple
                                    search
                                    selection
                                    scrolling
                                    className={`lead-upload-dropdown ${uploadAgent?'w-100':'w-50'}`}
                                    onChange={handleStaffSelect}
                                    options={uploadStaff}
                                />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="px-0    ">
                        <div onClick={handleUploadClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                        <button onClick={handleSubmit} className='btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-md-3'>Submit</button>
                    </div>
                </div>
            </Form >
        </div >
    )
}

export default LeadUplodForm