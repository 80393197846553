import { createContext, useState } from "react";


const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});


    //Collecing auth data from localStorage
    const accessToken = localStorage.getItem("accessToken");
    const userName = localStorage.getItem("userName");
    const passWord = localStorage.getItem("passWord");
    const role = localStorage.getItem("role");
    const image = localStorage.getItem("image")
    // const role = "company"  //company||agent

    if (accessToken && role && !auth.accessToken) {

        setAuth({ userName, passWord, accessToken, role, image });
    };
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )


}

export default AuthContext;